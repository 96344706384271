@font-face {
  font-family: Rubik-Regular;
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/Rubik/Rubik-Regular.ttf)
    format("truetype");
}

@font-face {
  font-family: Rubik-Medium;
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/Rubik/Rubik-Medium.ttf)
    format("truetype");
}

@font-face {
  font-family: GothamRounded-Bold;
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/gotham-rounded/GothamRounded-Bold.otf)
    format("truetype");
}

@font-face {
  font-family: GothamRounded-Medium;
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/gotham-rounded/GothamRounded-Medium.otf)
    format("truetype");
}

@font-face {
  font-family: Banda_Niera-Medium;
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/BandaNieraMedium/Banda_Niera-Medium.ttf)
    format("truetype");
}

@font-face {
  font-family: "MADE Tommy Soft";
  src: url("../assets/fonts/made_tommy_soft\ \(1\)/bold.otf") format("truetype");
}

@font-face {
  font-family: "MADE Tommy light";
  src: url("../assets/fonts/made_tommy_soft\ \(1\)/MADE Tommy Soft Light PERSONAL USE.otf") format("truetype");
}

@font-face {
  font-family: "MADE soft medium";
  src: url("../assets/fonts/made_tommy_soft\ \(1\)/MADE Tommy Soft Medium PERSONAL USE.otf") format("truetype");
}

/*# sourceMappingURL=typography.css.map */
