@import "../../Styles/Color.scss";
@import "../../Styles/typography.css";
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");

.frontpage_wrapper {
  background-color: $main-bgcolor;

  .frontpage_main_section {
    min-height: 75vh;

    // START SLIDER SECTION
    .frontpage_slider_section {
      .frontpage_slider {
        padding-top: 102px;

        .slick-next {
          position: absolute;
          right: 60px;
          z-index: 99;
          top: 41%;

          &::before {
            font-size: 60px !important;
            color: $btncolor !important;
          }
        }

        .slick-prev {
          position: absolute;
          left: 28px;
          z-index: 99;
          top: 41%;

          &:before {
            font-size: 60px !important;
            color: $btncolor !important;
          }
        }

        .festival_banner {
          background-image: linear-gradient(to right,
              #fed8c5,
              #ffcebf,
              #ffc4bc,
              #febabc,
              #fbb1be);

          .decoration_div {
            position: absolute;
            left: 0;
            top: 0 !important;
          }

          .decoration-div {
            position: absolute;
            top: 0 !important;
            right: 0 !important;
          }

          p {
            color: #3c0c0c !important;
            text-shadow: none !important;
            font-size: 52px !important;
            width: 52% !important;
            top: 11% !important;
            line-height: 75px;

            .main_content {
              margin-top: 15px !important;
              margin-bottom: 28px !important;
              display: block;
            }

            .main {
              color: black !important;
              display: inline !important;
              color: #d4433e !important;
              font-family: "MADE Tommy light" !important;
              font-weight: normal !important;
            }

            .differentColor {
              color: #3b0b0b !important;
              font-family: Banda_Niera-Medium !important;
            }

            span {
              color: #d4433e !important;
              font-family: "MADE Tommy Soft" !important;
              font-size: 87px;
            }
          }

          .main_img {
            right: 0;
          }
        }

        .new_banner {
          background-color: #d8d8e4 !important;

          .new_banner_img {
            top: 0;

            .try-beta-version {
              cursor: pointer;
            }
          }

          p {
            right: 0;
            text-align: center;
            color: #072630 !important;
            width: 55% !important;

            span {
              background-color: #00bbf2;
              border-radius: 0px 20px;
              color: $primary_White !important;
              font-family: "Bree Serif";
              font-size: 50px;
              padding: 0px 27px;
              box-shadow: 11px 4px 22px rgba(0, 187, 242, 0.46);
            }
          }

          .meta-verse {
            font-size: 50px !important;
          }

          .try-beta-version {
            position: absolute;
            right: 15%;
            background-color: #00bbf2;
            border-radius: 0px 20px;
            color: $primary_White !important;
            font-family: "Bree Serif";
            font-size: 35px;
            padding: 5px 27px;
            box-shadow: 11px 4px 22px rgba(0, 187, 242, 0.46);
            top: 40%;
            border: none;
            text-decoration: none;
          }
        }

        .festival_second_banner {
          background-color: $primary_White !important;

          p {
            color: #5e899a !important;
            text-shadow: none !important;
            width: 52% !important;
            padding-left: 105px !important;
            top: 15% !important;

            .differentColor {
              color: #3cbef2 !important;
              font-family: "MADE Tommy Soft" !important;
            }

            .third_line {
              font-size: 50px;
            }

            span {
              color: #5e899a !important;
              font-family: Banda_Niera-Medium !important;
              font-size: 87px;
            }

            button {
              background: #3cbef2;
              border: none;
              color: $primary_White;
              font-family: Banda_Niera-Medium !important;
              width: 245px;
              height: 60px;
              font-size: 40px;
              border-radius: 15px;
              cursor: pointer;
            }
          }

          .main_img {
            right: 0;
            top: 0px;
          }
        }

        .second_slider {
          // background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/home.jpg) !important;
          // background-image: url(../../assets/img/front/home.jpg) !important;
          background-color: #dd805f !important;

          width: 100%;
          height: 507px !important;
          position: relative;

          p {
            right: 139px;
            width: 38% !important;
            padding-left: 0 !important;
          }
        }

        .third_slider {
          // background-image: url("../../assets/img/front/Home.jpg");
          background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/Home.jpg);
          background-color: #c5b9fb !important;
          height: 507px;

          p {
            position: absolute;
            font-family: Banda_Niera-Medium;
            font-size: 70px;
            padding-left: 0px !important;
            width: 36% !important;
            color: #3f3679 !important;
            text-shadow: 2px 2px transparent !important;
            top: 50% !important;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            line-height: 64px;

            span {
              font-family: MADE Tommy Soft;
              color: #7057f1 !important;
            }
          }
        }

        .slick-slide img {
          display: block;
          position: absolute;
          top: 22px;
        }

        .frontpage_banner {
          // background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/front-banner.png);
          background-color: #06aed3;
          width: 100%;
          height: 507px;
          position: relative;

          .Card_Container {
            width: 100%;
            height: inherit;
          }

          .first_banner {
            position: absolute;
            right: 0;
            top: 31%;
          }

          p {
            position: absolute;
            font-family: Banda_Niera-Medium;
            font-size: 70px;
            padding-left: 152px;
            width: 45%;
            color: $primary_White;
            text-shadow: 2px 2px #058ead66;
            top: 25%;

            span {
              // color: $fronttitle;
              color: #fdf502;
            }
          }
        }

        .about_content {
          p {
            color: $titlecolor;
            text-align: center;
            font-size: 25px;
            width: 72%;
            margin: auto;
            line-height: 34px;
            padding: 25px 0px;
          }
        }
      }
    }

    // END SLIDER SECTION

    // START FREE DEMO CLASS

    .free_class_title {
      background-color: $primaryblue;
      width: fit-content;
      margin: auto;
      padding: 15px 21px;
      border-radius: 47px 47px 0px 0px;

      .slick-next {
        position: absolute !important;
        right: -74px !important;
        z-index: 99 !important;
        top: 46% !important;
        display: block !important;

        &::before {
          font-size: 60px !important;
          color: $btncolor !important;
          display: block !important;
        }
      }

      .slick-prev {
        position: absolute;
        left: 44px;
        left: -110px;
        z-index: 99;
        top: 46%;
        display: block !important;

        &:before {
          font-size: 60px !important;
          color: $btncolor !important;
          display: block !important;
        }
      }

      h2 {
        font-family: Banda_Niera-Medium;
        font-size: 60px;
        text-align: center;
        font-weight: 100;
        color: $primary_White;

        span {
          color: $fronttitle;
        }
      }
    }

    .free_demo_class {
      .slick-prev {
        left: -55px;

        &:before {
          font-size: 40px;
          color: $btncolor !important;
        }
      }

      .slick-next {
        right: -26px;

        &:before {
          font-size: 40px;

          color: $btncolor !important;
        }
      }

      .Card_Container {
        padding: 40px 30px;
        width: 90%;
        border: 3px solid #fff;
        background-color: hsla(0, 0%, 100%, 0.5294117647058824);
        border-radius: 35px;
        margin: 0px auto 0px auto;

        .cards_row {
          display: flex;
          justify-content: left;
          margin: auto;
          flex-wrap: wrap;
          gap: 30px 0px;
          padding-bottom: 0%;
        }

        .Common_Card_Main_Container {
          margin: 0 0px;
          width: 20%;

          // width: 331px;
          .no-class-found {
            text-align: center;
            margin: auto;
            display: flex;
            justify-content: center;
          }

          .Common_Card_Container {
            .Card_Title_Container {
              .C_Title {
                width: 80%;
              }
            }
          }

          // .Common_Card_Container {
          //   border-radius: 52px;
          //   box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
          //   height: fit-content;
          //   width: 350px;
          //   margin: auto auto 10px auto !important;
          //   border: 4px solid #ffffff;
          //   &:hover {
          //     box-shadow: #bbe8f6 0px 3px 8px;
          //   }
          //   .Card_Image_Container {
          //     cursor: pointer;
          //     cursor: pointer;
          //     width: 100%;
          //     margin: 0%;
          //     position: relative;
          //     margin-top: -1px !important;
          //     margin: auto;
          //     display: flex;
          //     justify-content: center;
          //     padding-top: 22px;
          //     height: auto;
          //     .Card_Img {
          //       object-fit: cover;
          //       border-radius: 31px;
          //       border: 5px solid #ffffff70;

          //       width: 293px;
          //       height: 159px;
          //       object-fit: cover;
          //     }
          //     .play_Btn {
          //       position: absolute;
          //       top: 47%;
          //       right: 45%;
          //       opacity: 0.5;
          //       cursor: pointer;
          //       background-color: #000;
          //       width: 30px;
          //       height: 30px;
          //       display: flex;
          //       align-items: center;
          //       justify-content: center;
          //       border-radius: 50px;
          //       color: $primary_White;
          //       font-size: 12px;
          //       box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
          //     }
          //     .mostReviewed {
          //       position: absolute;
          //       top: 70%;
          //       right: -1px;
          //       color: $primary_White;
          //       padding: 5px 10px 5px 30px;
          //       font-size: 13px;
          //       letter-spacing: 1px;
          //       border: 2px solid white;
          //       border-right: none;
          //       background: #aaa2f7;
          //       clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);
          //       display: none;
          //       &::after {
          //         content: "";
          //         position: absolute;
          //         width: 15px;
          //         height: 38px;
          //         background: $primary_White;
          //         top: -1px;
          //         left: 0;
          //         transform: rotate(-38deg);
          //       }
          //     }
          //   }
          //   .Card_Title_Container {
          //     cursor: pointer;
          //     display: flex;
          //     margin: 15px 20px;
          //     align-items: center;
          //     justify-content: space-between;
          //     .C_Title {
          //       font-size: 18px;
          //       word-break: break-word;
          //       text-transform: uppercase;
          //       height: 45px;
          //       width: 75%;
          //       margin-right: 10px;
          //       color: $primary_White;
          //       font-weight: bold;
          //       cursor: pointer;
          //       font-weight: 500;
          //       overflow: hidden;
          //       text-overflow: ellipsis;
          //       display: -webkit-box;
          //       -webkit-line-clamp: 2;
          //       -webkit-box-orient: vertical;
          //     }
          //     .heart_Container {
          //       background-color: $primary_White;
          //       width: 35px !important;
          //       height: 35px !important;
          //       border-radius: 50px;
          //       display: flex;
          //       align-items: center;
          //       justify-content: center;
          //       border: 3px solid rgba(255, 255, 255, 0.432);
          //       cursor: pointer;
          //       img {
          //         width: 20px;
          //       }
          //     }
          //   }
          //   .Card_Age_Container {
          //     cursor: pointer;
          //     display: flex;
          //     justify-content: space-between;
          //     align-items: center;
          //     .Age_Left_Side {
          //       border-radius: 0px 30px 30px 0px;
          //       font-size: 20px;
          //       font-weight: 900;
          //       color: $primary_White;
          //       background-color: #00000029;
          //       height: 47px;
          //       width: 199px;
          //       border: 3px solid #ffffff70;
          //       display: flex;
          //       justify-content: center;
          //       border-left: hidden;
          //       padding: 8px 30px;
          //     }
          //     .Eye_Right_Side {
          //       display: flex;
          //       flex-direction: column;
          //       align-items: center;
          //       justify-content: center;
          //       margin-right: 15px;
          //       p {
          //         margin-top: 5px;
          //         color: rgb(255, 255, 255);
          //       }
          //     }
          //   }
          //   .Price-Container {
          //     width: 100%;
          //     display: block;
          //     align-items: center;
          //     margin-top: 15px;
          //     color: #fff;
          //     text-align: center;
          //     .Ratting {
          //       border: 3px solid $primary_White;
          //       padding: 10px;
          //       border-right: none;
          //       border-radius: 20px 0 0 20px;
          //       display: flex;
          //       align-items: center;
          //       display: none;
          //       .Start_Img {
          //         width: 100%;
          //         height: 17px;
          //         object-fit: scale-down;
          //         padding-right: 7px;
          //       }
          //     }
          //     span.rating_class {
          //       display: block !important;
          //       margin: auto;

          //       span {
          //         &:before {
          //           margin: 0px 8px;
          //         }
          //       }
          //     }
          //     .Price {
          //       display: flex;
          //       align-items: center;
          //       margin-left: 15px;
          //       .Price-text {
          //         font-size: 32px;
          //         font-weight: bold;

          //         span {
          //           margin-right: 5px;
          //           display: none;
          //         }
          //       }
          //       .Sub-Price {
          //         margin-left: 5px;
          //         display: flex;
          //         flex-direction: column;
          //         font-size: 14px;
          //         display: none;
          //       }
          //     }
          //   }

          //   .Card_Bottom_Container {
          //     cursor: pointer;
          //     display: flex;
          //     flex-direction: column;
          //     width: 100%;
          //     align-items: center;
          //     .Card_Bottom {
          //       background-color: white;
          //       display: flex;
          //       align-items: center;
          //       width: 344px;
          //       width: 100%;
          //       margin-top: 18px;
          //       padding: 20px;
          //       border-radius: 0px 40px 46px 46px;

          //       img {
          //         border-radius: 50%;
          //         width: 50px;
          //         height: 50px;
          //         object-fit: cover;
          //         margin-right: 10px;
          //       }
          //       .edu_badgeLevel {
          //         border-radius: 0;
          //         padding: 12px;
          //         height: auto;
          //       }
          //       p {
          //         font-size: 20px;
          //         margin-left: 0;
          //         height: 21px;
          //         color: $Primary_Font_Color;
          //         overflow: hidden;
          //         text-overflow: ellipsis;
          //         display: -webkit-box;
          //         -webkit-line-clamp: 1;
          //         -webkit-box-orient: vertical;
          //       }
          //     }
          //   }
          // }
        }
      }

      .Explore_More_Btn {
        text-align: center;

        button {
          margin: auto;
          padding: 15px 29px;
          background-color: #08b6db;
          border: 3px solid #fff;
          width: 241px;
          height: 66px;
          color: #fff;
          border-radius: 0 0 21px 21px;
          font-size: 22px;
          cursor: pointer;
          transition: 0.5s !important;
          font-family: GothamRounded-Bold;

          &:hover {
            background-color: $Active_Button_Color;
          }
        }
      }
    }

    // END FREE DEMO CLASS

    // START CATEGORY

    .category_main_section {
      .category_row {
        .category_title {
          h2 {
            font-family: Banda_Niera-Medium;
            font-size: 64px;
            color: $titlecolor;
            text-align: center;
            font-weight: 100;
            margin-top: 65px;
          }

          .Blue_Wawe {
            width: 242px;
            height: 30px;
            object-fit: contain;
            position: relative;
            top: 3px;
            margin: auto;
            display: block;
          }
        }

        .category_container_section {
          width: 84%;
          height: auto;
          background-color: $category;
          border-radius: 33px;
          margin: 33px auto 20px auto;
          border: 2px solid $primary_White;
          padding: 55px 0px;

          .category_container {
            display: flex;
            flex-wrap: wrap;
            gap: 27px 37px;
            justify-content: center;

            .category_box {
              width: 331px;
              height: 125px;
              background-color: #c5a8e0;
              border-radius: 31px;
              border: 3px solid #ffffffcf;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0px 10px;
              transition: 0.2s !important;
              cursor: pointer;

              &:hover {
                box-shadow: 0 3px 14px 6px rgb(0 0 0 / 10%);
              }

              .category_image {
                width: 70%;
                height: 107px;
                border-radius: 25px;
                background: linear-gradient(45deg,
                    rgba(255, 255, 255, 0.31976540616246496) 0%,
                    rgba(255, 255, 255, 0.5970763305322129) 79%,
                    rgba(255, 255, 255, 1) 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 3px 8px 0px rgb(0 0 0 / 15%);

                img {
                  width: 70px;
                  height: 70px;
                  object-fit: contain;
                }
              }

              .category_name {
                margin: auto;
                text-align: center;
                width: 100%;

                p {
                  font-size: 21px;
                  color: $primary_White;
                  width: 90%;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    // END CATEGORY

    //START POPULAR CLASS
    .free_demo_class {
      .popular_class_box_section {
        padding-top: 0;

        .class_title {
          margin: 60px 0px;

          h2 {
            font-family: Banda_Niera-Medium;
            font-size: 64px;
            color: $titlecolor;
            text-align: center;
            font-weight: 100;
          }

          .Blue_Wawe {
            width: 242px;
            height: 30px;
            object-fit: contain;
            position: relative;
            top: 3px;
            margin: auto;
            display: block;
          }
        }
      }
    }

    //END POPULAR CLASS

    // START BANNER

    .site_banner_section {
      .slick-next {
        position: absolute !important;
        right: 88px !important;
        z-index: 99 !important;
        top: 46% !important;

        &::before {
          font-size: 60px !important;
          color: $btncolor !important;
        }
      }

      .slick-prev {
        position: absolute;
        left: 44px;
        z-index: 99;
        top: 46%;

        &:before {
          font-size: 60px !important;
          color: $btncolor !important;
        }
      }

      .site_banner_row {
        .site_banner {
          background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/BANNER.png);
          width: 100%;
          height: 280px;
          background-repeat: no-repeat;
          background-position: center;
          margin: 6% 0%;
          display: flex !important;
          justify-content: center;
          align-items: center;
          background-size: contain;

          h1 {
            font-size: 58px;
            color: $primary_White;
            justify-content: center;
            width: 40%;
            letter-spacing: 1.5px;

            span {
              color: $Active_Button_Color;
            }
          }
        }
      }
    }

    // END BANNER

    .Ultra_Pro_section {
      padding: 0px 150px 160px;
      margin-bottom: 44px !important;
      background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/Ultra_Pro/bg_shap.png");
      background-position: right top;
      background-repeat: no-repeat;
      margin-bottom: -100px;

      // padding: 205px 0;
      .Ultra_Pro_title_row {
        .Top_Cont {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 25px;
          width: 670px;
          margin: auto;
          text-align: center;

          p {
            color: #8484a0;
            font-size: 60px;
            font-family: Banda_Niera-Medium;
            z-index: 0;
          }

          img.Blue_Wawe {
            width: 203px;
            height: 20px;
            object-fit: contain;
            position: relative;
            top: 3px;
          }
        }
      }

      .Ultra_Pro_content_row {
        display: grid;
        grid-template-columns: 63% 37%;

        // background-image: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/Ultra_Pro/bg_shap.png");
        .Ultra_Pro_col_01 {
          display: grid;
          grid-template-columns: 80% 20%;

          .Ultra_Pro_content_block {
            margin: auto 0;

            .Ultra_Pro_min_font {
              font-size: 38px;
              color: #8484a0;
              font-weight: 600;
            }

            .Ultra_Pro_big_title {
              font-size: 68px;
              color: #06aed3;
              font-weight: 800;
              position: relative;
              margin-bottom: 35px;

              &:after {
                content: "";
                width: 200px;
                height: 5px;
                position: absolute;
                left: 0;
                bottom: -10px;
                background-color: #31bbda;
                border-radius: 100px;
              }
            }

            ul.Ultra_Pro_text {
              li.Ultra_Pro_li {
                font-size: 22px;
                color: #8484a0;
                padding-bottom: 10px;

                &::marker {
                  color: #06aed3;
                  font-size: 35px;
                }
              }
            }
          }

          .Ultra_Pro_btn_block {
            margin: auto;

            .btn_outter:nth-child(1) {
              margin-bottom: 15px;
            }

            .btn_outter {
              a.btn_inner {
                padding: 6px;
                text-decoration: none;
                border-radius: 50px;
                color: #fff;
                font-size: 23px;
                font-weight: 500;
                text-align: center;
                display: flex;
                width: 215px;
                background-color: rgb(8, 182, 219);
                box-shadow: inset -1.597px 1.204px 8px 0px rgba(0, 0, 0, 0.21);
                border: 3px solid #2bcaed;

                img {
                  width: auto;
                  height: 55px;
                  padding-right: 10px;
                }

                span.btn_inner_text {
                  margin: auto 0;
                }
              }
            }
          }
        }

        .Ultra_Pro_col_02 {
          position: relative;

          img.ab_ultra_bell {
            position: absolute;
            top: 10%;
            left: -20%;
          }

          img.ab_ultra_play {
            position: absolute;
            right: 9%;
            top: -4%;
          }

          img.ab_ultra_onlinelaunching {
            position: absolute;
            bottom: 35%;
            right: -3%;
          }
        }
      }
    }

    // START SIGNUP SECTION

    .sign_up_section {
      .sign_up_grid_row {
        margin: 7% 0 47px 0;

        .sign_up_grid {
          display: flex;
          justify-content: center;
          gap: 0 24px;

          .sign_up_grid_item {
            width: 518px;
            height: 451px;
            background-color: $primary_White;
            border-radius: 34px;
            position: relative;

            &:nth-child(3)img {
              top: -86px !important;
              left: 23%;
            }

            &:nth-child(2)img {
              top: -124px !important;
            }

            img {
              position: absolute;
              top: -150px;
              margin: auto;
              text-align: center;
              display: flex;
              left: 16%;
            }

            .title {
              text-align: center;
              margin-top: 34% !important;
              font-size: 23px;
              color: $primaryblue;
              font-family: Rubik-Medium;
              width: 50%;
              margin: auto;
            }

            p {
              font-family: Rubik;
              font-size: 20px;
              text-align: center;
              width: 68%;
              margin: auto;
              margin-top: 25px;
              line-height: 27px;
            }

            .sign_up_btn {
              margin: auto;
              display: flex;
              justify-content: center;

              button {
                width: 310px;
                height: 66px;
                background-color: $primaryblue;
                position: absolute;
                bottom: -28px;
                border: 4px solid $primary_White;
                border-radius: 14px;
                color: $primary_White;
                // font-family: GothamRounded-Bold;
                font-family: GothamRounded-Medium;
                font-size: 24px;
                cursor: pointer;
                transition: 0.5s !important;

                &:hover {
                  background-color: $Active_Button_Color;
                }
              }
            }
          }
        }
      }
    }

    // END SIGNUP SECTION

    // START FIND OUT SECTION
    .find_out_body {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0 85px;
      padding: 7% 0px 25px 0px;

      .find_out_section {
        .find_out_row {
          .find_out_col {
            h2 {
              color: $primaryblue;
              font-size: 41px;
              font-weight: 500;
              padding-left: 23px;
              padding-bottom: 20px;
              font-family: "Rubik";
            }

            .find_out_left {
              .active-tabs {
                background-color: $primaryblue;
                color: $primary_White;

                &::before {
                  content: "";
                  width: 9px;
                  height: 57px;
                  background-color: $primaryblue;
                  position: absolute;
                  top: 23px;
                  left: -20px;
                  border-radius: 50px;
                }
              }

              button {
                margin-bottom: 15px;
                display: block;
                width: 447px;
                height: 94px;
                border-radius: 10px;
                background-color: $primary_White;
                border: none;
                font-size: 21px;
                letter-spacing: 0.3px;
                color: $teachcolor;
                font-weight: 500;
                position: relative;
                text-align: left;
                padding: 0px 30px;
                cursor: pointer;
              }
            }
          }
        }
      }

      .find_out_more_section {
        .find_out_more_row {
          .find_out_more_col {
            .Card_Img {
              width: 727px;
              height: auto;
              border-radius: 10px;
              border: 4px solid $primaryblue;
              background: #0091be;
            }
          }
        }
      }
    }

    // END FIND OUT SECTION

    .teach_fifth_section_row {
      font-family: Rubik;

      .main_fifth_section {
        .title_section {
          h1 {
            color: $primaryblue;
            text-align: center;
            font-weight: 600;
            font-size: 35px;
            margin-bottom: 26px;
          }
        }

        .fifth_grid_section {
          background-color: #effdff;
          display: flex;
          flex-wrap: wrap;
          padding: 60px 0px;
          justify-content: center;
          gap: 50px 40px;

          .last_box_div {
            background-color: $primary_White;
            // display: block !important;

            .last_box_content {
              margin: auto;

              &:nth-child(1) h1 {
                color: $teachcolor;
                font-size: 29px;
                font-weight: 600;
              }

              .second {
                color: $primaryblue !important;
                font-size: 37px !important;
                font-weight: 600 !important;
              }
            }
          }

          .grid_items_box {
            width: 511px;
            height: 122px;

            border-radius: 23px;
            display: flex;

            .category_icon {
              width: 156px;
              height: 125px;
              background-color: $primaryblue;
              border-radius: 23px;
              display: flex;
              justify-content: center;
              align-items: center;

              .category {
                width: 90px !important;
                height: 90px !important;
              }

              img {
                width: 80px;
                height: 80px;
                object-fit: contain;
              }
            }

            .category_content {
              padding-left: 26px;

              h2 {
                color: $teachcolor;
                margin-bottom: 14px;
              }

              span {
                font-size: 20px;
                font-weight: 500;
                color: #8384a2;
                line-height: 31px;
                display: block;
              }
            }
          }
        }

        .support_main_section_row {
          .support_section_div {
            color: $teachcolor;
            padding: 63px 277px;
            display: flex;
            align-items: center;
            background-color: $primary_White;
            margin-top: 53px;

            .left_section {
              h1 {
                font-weight: 600;
                font-size: 27px;
                width: 79%;
                line-height: 37px;
                margin-right: 70px;
              }
            }

            .right_section {
              h1 {
                font-size: 27px;
                line-height: 37px;
                width: 100%;
                font-weight: 600;
              }

              .first {
                margin-bottom: 15px;
                font-size: 21px;
                width: 69%;
                color: $teachcolor;
              }

              .email_section {
                display: flex;
                align-items: center;
                margin-top: 11px;

                border: 3px solid #c0eafc;
                width: 368px;
                padding: 0px 25px;
                border-radius: 15px;
                transition: 0.3s !important;

                &:hover {
                  box-shadow: #bbe8f6 0px 3px 8px;
                }

                .mail_box {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 21px;
                  margin-right: 15px;

                  a {
                    padding-top: 6px;

                    img {
                      width: 45px;
                    }
                  }
                }

                .email_add {
                  border-radius: 21px;

                  h1 {
                    padding: 8px 0px;
                    font-size: 19px;
                  }
                }
              }

              .email_add {
                a {
                  color: $teachcolor;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }

    //START ANY ENQUIRY
    .enquiry_section_row {
      .enquiry_section_col {
        h1 {
          color: $titlecolor;
          font-family: Banda_Niera-Medium;
          text-align: center;
          margin: 25px 25px;
          font-size: 50px;
          font-weight: 100;
        }

        .main_form {
          margin: auto;
          display: flex;
          justify-content: center;
          background-color: $primary_White;
          width: fit-content;
          padding: 26px 35px;
          margin-bottom: 35px;
          border-radius: 20px;

          .enquiry_filed {
            margin-bottom: 10px;

            label {
              display: block;
              margin-bottom: 6px;
              color: $primary_black;
            }

            input {
              width: 100%;
              height: 45px;
              border-radius: 7px;
              border: 3px solid #c0eafc;
              outline: none;
              padding: 10px;
              font-size: 16px;
              font-family: GothamRounded-Medium;
              color: $primary_black;
            }

            textarea {
              width: 100%;
              border: 3px solid #c0eafc;
              border-radius: 7px;
              outline: none;
              padding: 10px;
              font-family: GothamRounded-Medium;
              color: $primary_black;
              font-size: 16px;
            }
          }

          .flex_input {
            display: flex;
            gap: 0 20px;

            input {
              width: 320px;
              font-size: 16px;
              padding: 10px;
              font-family: GothamRounded-Medium;
              color: $primary_black;
            }
          }

          .submit_btn {
            display: flex;
            justify-content: center;

            button {
              width: 140px;
              height: 50px;
              border-radius: 10px;
              background-color: $primaryblue;
              border: none;
              color: $primary_White;
              font-size: 18px;
              font-family: GothamRounded-Medium;
              border: 3px solid transparent;
              cursor: pointer;
              transition: 0.3s !important;

              &:hover {
                border: 3px solid $primaryblue;
                background-color: transparent;
                color: $primaryblue;
              }
            }
          }
        }
      }
    }

    //ENY ANY ENQUIRY
    .front-story-wrapper {
      width: 90%;
      padding: 40px 30px;
      margin: 0px auto 0px auto;

      .front-story-heading {
        color: #06add2;
        font-size: 40px;
        font-weight: 500;
        font-family: "Rubik";
      }

      .front-story-txt {
        color: #8483a2;
        font-size: 16px;
        margin: auto;
        line-height: 25px;
        padding: 10px 0px;
      }
    }
  }
}

@media all and (min-width: 320px) and (max-width: 480px) {
  .frontpage_wrapper {
    .frontpage_main_section {
      .front-story-wrapper {
        width: 100%;
        padding: 5px 10px;
        height: 500px;
        overflow: auto;

        .front-story-heading {
          font-size: 25px;
        }

        .front-story-txt {
          padding: 10px 0px;
          font-size: 12px;
          line-height: 18px;
        }
      }

      .frontpage_slider_section {
        .frontpage_slider {
          padding-top: 72px;

          .new_banner {
            .new_banner_img {
              top: auto !important;
              bottom: 0 !important;
              width: 100%;
              height: 225px;
              left: 0;
              margin: auto;
              object-fit: cover;
            }

            p {
              width: 100% !important;

              span {
                font-size: 26px;
                margin-top: 17px !important;
                display: block;
                width: fit-content;
                margin: auto;
              }
            }
          }

          .festival_banner {

            .decoration_div,
            .decoration-div {
              width: 35px;
            }

            .main_img {
              right: 0;
            }

            p {
              line-height: 24px;
              font-size: 21px !important;
              width: 80% !important;
              padding-left: 123px;
              top: 4% !important;
              margin-top: 0 !important;

              span {
                font-size: 26px;
              }

              .main_content {
                margin-top: 6px !important;
                margin-bottom: 14px !important;
              }
            }

            span {
              font-size: 80px;
            }

            .main_img {
              width: 290px;
              top: 63%;
              right: 0;
              left: 0;
              margin: auto;
            }
          }

          .festival_second_banner {
            p {
              font-size: 30px;
              width: 100% !important;
              z-index: 1;
              line-height: 42px;
              top: 0% !important;
              padding-left: 0px !important;
              margin-top: 10px !important;

              span {
                font-size: 23px;
              }

              .third_line {
                font-size: 22px;
              }

              button {
                width: 130px;
                height: 34px;
                font-size: 23px;
                margin-top: 10px;
                border-radius: 10px;
              }
            }

            .main_img {
              right: 0;
              top: 42%;
              width: 100%;
              height: 226px;
              object-fit: cover;
              left: 0;
              margin: auto;
            }
          }

          .second_slider {
            background-position: 27% !important;

            p {
              right: 0;
              width: 100% !important;
            }
          }

          .slick-next {
            right: 11px;
            top: 39%;

            &:before {
              font-size: 25px !important;
              color: $btncolor !important;
            }
          }

          .slick-prev {
            left: 4px;
            top: 39%;

            &:before {
              font-size: 25px !important;
              color: $btncolor !important;
            }
          }

          .slick-arrow {
            display: block !important;
            top: 48%;
          }

          .frontpage_mb_banner {
            // background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/front-mb-banner.png);
            width: 100%;
            height: 395px !important;
            background-position: center;
          }

          .frontpage_banner {
            height: 395px;

            .first_banner {
              right: 0;
              top: 63%;
              width: 333px;
              margin: auto;
              left: 0;
            }

            p {
              font-size: 34px;
              padding-left: 0;
              width: 88%;
              top: 3%;
              position: relative;
              text-align: center;
              margin: auto;
              margin-top: 5px;
            }

            .meta-verse {
              font-size: 34px !important;
            }

            .try-beta-version {
              font-size: 20px;
              top: 25%;
            }

            .second_banner_img {
              display: block;
              width: 317px;
              top: 55%;
            }
          }

          .about_content {
            p {
              font-size: 13px;
              width: 92%;
              line-height: 15px;
              padding: 10px 0px;

              span {
                font-weight: 600;
              }
            }
          }

          .third_mb_banner {
            background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/home_mb.jpg) !important;
            // background-image: url("../../assets/img/front/home_mb.jpg") !important;
            width: 100%;
            height: 507px;
          }

          .third_slider {
            background-image: none;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 100%;
            height: 395px;

            p {
              width: 100% !important;
              font-size: 34px !important;
              line-height: 33px;
              margin: 0;
              margin-top: 0 !important;

              span {
                font-size: 34px;
              }
            }
          }
        }
      }

      .free_class_title {
        padding: 10px 21px;
        border-radius: 0;
        width: 100%;

        h2 {
          font-size: 27px;
        }
      }

      .free_demo_class {
        .slick-arrow {
          display: block !important;
        }

        .slick-prev {
          left: -32px;

          &::before {
            font-size: 30px;
          }
        }

        .slick-next {
          right: -18px;

          &::before {
            font-size: 30px;
          }
        }

        .popular_class_box_section {
          .class_title {
            margin: 25px 0px 25px 0px;

            .Blue_Wawe {
              width: 183px;
              height: 12px;
            }

            h2 {
              font-size: 40px;
            }
          }
        }

        .cards_row {
          grid-gap: 0px 0px !important;
          gap: 0px 0px !important;
        }

        .Card_Container {
          padding: 5px;
          border: none;
          background-color: transparent;
          width: 100%;

          .Common_Card_Main_Container {
            width: 95%;
            margin: auto;

            .Common_Card_Container {
              width: 100%;
              border-radius: 14px;

              .Card_Bottom_Container {
                .Card_Bottom {
                  padding: 5px 10px;
                  border-radius: 0px 0px 9px 9px;
                  margin-top: 0;
                }
              }

              .Card_Image_Container {
                padding-top: 0px;
                height: 100px;

                .Card_Img {
                  width: 100%;
                  height: 100px;
                  border-radius: 11px 11px 0 0;
                }
              }
            }
          }
        }

        .Explore_More_Btn {
          button {
            padding: 10px 29px;
            width: 100%;
            height: 55px;
            font-size: 19px;
          }
        }
      }

      .category_main_section {
        .category_row {
          .category_container_section {
            width: 95%;
            padding: 15px 10px;
            margin: 25px auto 25px auto;

            .category_container {
              display: flex;
              grid-gap: 5px;
              gap: 5px;

              .category_box {
                width: 49%;
                height: auto;
                border-radius: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .category_name {
                  p {
                    font-size: 15px;
                    width: 100%;
                    padding-bottom: 5px;
                  }
                }

                .category_image {
                  width: 55px;
                  height: 55px;
                  border-radius: 12px;
                  margin-top: 10px;

                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
              }
            }
          }

          .category_title {
            h2 {
              font-size: 42px;
              margin-top: 15px;
            }

            .Blue_Wawe {
              width: 156px;
              height: 10px;
            }
          }
        }
      }

      .site_banner_section {
        .slick-next {
          right: 0px !important;

          &::before {
            font-size: 29px !important;
          }
        }

        .slick-prev {
          left: -10px;

          &::before {
            font-size: 29px !important;
          }
        }

        .site_banner_row {
          padding: 0px 10px;

          .site_banner {
            background-image: none;
            background-image: linear-gradient(to bottom,
                #58ccde,
                #51c5d9,
                #4abfd5,
                #42b8d0,
                #3bb2cb);
            border-radius: 28px;
            height: 105px;

            h1 {
              font-size: 19px;
              width: 88%;
              text-align: center;
            }
          }
        }
      }

      .Ultra_Pro_section {
        padding: 60px 10px 50px;
        background-position: 62% 16%;
        background-size: auto 460px;

        .Ultra_Pro_title_row {
          .Top_Cont {
            width: auto;

            p {
              font-size: 28px;
            }
          }
        }

        .Ultra_Pro_content_row {
          grid-template-columns: 100%;
          display: flex;
          flex-wrap: wrap-reverse;

          .Ultra_Pro_col_01 {
            grid-template-columns: 100%;
            display: block;

            .Ultra_Pro_btn_block {
              display: block;
              width: auto;
              padding: 25px 0;

              .btn_outter:nth-child(1) {
                margin-bottom: 10px !important;
              }

              .btn_outter {
                margin: auto !important;

                a.btn_inner {
                  padding: 3px;
                  width: 165px;
                  margin: auto;

                  img {
                    width: auto;
                    height: 32px;
                  }

                  span.btn_inner_text {
                    font-size: 20px;
                  }
                }
              }
            }

            .Ultra_Pro_content_block {
              .Ultra_Pro_min_font {
                font-size: 23px;
                padding-bottom: 0px;
              }

              .Ultra_Pro_big_title {
                font-size: 30px;
                margin-bottom: 20px;

                &:after {
                  width: 135px;
                }
              }

              ul.Ultra_Pro_text {
                padding-left: 25px;

                li.Ultra_Pro_li {
                  font-size: 16px;
                  padding-bottom: 0;
                  line-height: 1.3;

                  &::marker {
                    font-size: 20px;
                  }
                }
              }
            }
          }

          .Ultra_Pro_col_02 {
            display: flex;
            width: 300px;
            margin: auto;

            .ultra_mobile_cell {
              width: auto;
              height: 340px;
              margin: auto;
            }

            img.ab_ultra_onlinelaunching {
              right: 9%;
              width: auto;
              height: 60px;
              bottom: 22%;
            }

            img.ab_ultra_play {
              right: 4%;
              top: 0%;
              width: auto;
              height: 41px;
            }

            img.ab_ultra_bell {
              left: -1%;
              width: auto;
              height: 50px;
            }
          }
        }
      }

      .sign_up_section {
        .sign_up_grid_row {
          .sign_up_grid {
            display: block;

            .sign_up_grid_item {
              width: 94%;
              height: 323px;
              border-radius: 20px;
              margin: auto;
              margin-top: 116px;

              .title {
                width: 69%;
                font-size: 17px;
                margin-top: -55px !important;
                padding-top: 0;
                text-align: center;
                margin: auto;
              }

              p {
                font-size: 15px;
                width: 90%;
                margin-top: 12px;
                line-height: 24px;
              }

              &:nth-child(3) img {
                width: 182px;
                top: -65px !important;
                left: 0;
              }

              &:nth-child(2) img {
                width: 196px;
                top: -85px !important;
                left: 0;
              }

              img {
                width: 225px;
                top: -98px;
                left: 0;
                position: relative;
              }

              .sign_up_btn {
                button {
                  width: 183px;
                  font-size: 15px;
                  height: 46px;
                  bottom: -17px;
                  letter-spacing: 0.3px;
                }
              }
            }
          }
        }
      }

      .find_out_body {
        display: block;
        padding: 0px 0px 25px 0px;

        .find_out_section {
          .find_out_row {
            .find_out_col {
              h2 {
                text-align: center;
                padding-left: 0;
                font-size: 36px;
              }

              .find_out_left {
                gap: 0 12px;
                padding: 0px 12px;

                .active-tabs {
                  &::before {
                    width: 7px;
                    height: 43px;
                    top: 9px;
                    left: -15px;
                    display: none;
                  }
                }

                button {
                  margin: auto;
                  margin-bottom: 15px !important;
                  width: 100%;
                  height: 65px;
                  font-size: 14px;
                  padding: 0px 5px;
                  text-align: center;
                  letter-spacing: 0.3px;
                  line-height: 16px;
                }
              }
            }
          }
        }

        .find_out_more_section {
          .find_out_more_row {
            .find_out_more_col {
              .Card_Img {
                width: 93%;
                height: 253px;
                margin: auto;
                display: flex;
              }
            }
          }
        }
      }

      .teach_fifth_section_row {
        .main_fifth_section {
          .support_main_section_row {
            .support_section_div {
              padding: 20px 20px 20px 20px;
              display: block;
              margin-top: 12px;

              .left_section {
                h1 {
                  font-size: 20px;
                  line-height: 30px;
                  margin-right: 135px;
                  width: 100%;
                  text-align: center;
                }
              }

              .right_section {
                .first {
                  font-size: 17px;
                  width: 100%;
                  text-align: center;
                }

                .email_call_section {
                  // display: flex;
                }

                .email_section {
                  margin: 6px auto;
                  width: 100%;
                  padding: 0px 6px;

                  .mail_box {
                    border-radius: 12px;
                    justify-content: flex-start;
                    margin-right: 9px;

                    img {
                      width: 27px !important;
                    }
                  }
                }

                h1 {
                  font-size: 20px;
                  line-height: 30px;
                  text-align: center;
                }
              }
            }
          }

          .teaching_btn button {
            width: 185px;
            height: 43px;
            font-size: 18px;
          }

          .title_section {
            h1 {
              font-size: 24px;
              margin-bottom: 20px;
            }
          }

          .fifth_grid_section {
            padding: 15px 10px 56px 10px;
            gap: 20px 8px;

            .last_box_div {
              .last_box_content {
                &:nth-child(1) h1 {
                  font-size: 22px !important;
                }

                .second {
                  font-size: 32px !important;
                }
              }
            }

            .grid_items_box {
              width: 365px;
              height: auto;

              .category_content {
                padding-left: 17px;

                span {
                  font-size: 17px;
                }

                h2 {
                  margin-bottom: 6px;
                  font-size: 20px;
                }
              }

              .category_icon {
                width: 60px;
                height: 60px;
                border-radius: 16px;

                .category {
                  width: 44px !important;
                  height: 44px !important;
                }

                img {
                  width: 38px;
                  height: 38px;
                }
              }
            }
          }
        }
      }
    }

    .enquiry_section_row {
      .enquiry_section_col {
        h1 {
          font-size: 28px !important;
        }

        .main_form {
          width: 94% !important;
          padding: 20px 17px !important;

          .enquiry_filed {
            label {
              font-size: 15px !important;
            }
          }

          .enquiry_filed {
            textarea {
              height: 135px;
              font-size: 14px !important;
            }
          }

          .flex_input {
            display: block !important;

            input {
              width: 100% !important;
              height: 40px !important;
            }
          }

          .submit_btn button {
            width: 140px !important;
            height: 45px !important;
          }
        }
      }
    }
  }
}

@media all and (min-width: 481px) and (max-width: 767px) {
  .frontpage_wrapper .frontpage_main_section .frontpage_slider_section .frontpage_slider .frontpage_banner p {
    margin-top: 16px;
  }

  .frontpage_wrapper {
    .frontpage_main_section {
      .frontpage_slider_section {
        .frontpage_slider {
          .new_banner {
            .new_banner_img {
              top: auto !important;
              bottom: 0 !important;
              width: 100%;
              height: 320px;
              left: 0;
              margin: auto;
              object-fit: cover;
            }

            p {
              span {
                font-size: 26px;
                margin-top: 17px !important;
                display: block;
                width: fit-content;
                margin: auto;
              }
            }
          }

          .festival_banner {

            .decoration_div,
            .decoration-div {
              width: 50px;
            }

            .main_img {
              right: 0;
            }

            p {
              line-height: 32px;
              font-size: 28px !important;
              width: 80% !important;
              padding-left: 123px;
              top: 4% !important;
              margin-top: 0 !important;

              span {
                font-size: 30px;
              }

              .main_content {
                margin-top: 0px !important;
                margin-bottom: 0px !important;
              }
            }

            span {
              font-size: 80px;
            }

            .main_img {
              width: 410px;
              top: 59%;
              right: 0;
              left: 0;
              margin: auto;
            }
          }

          .festival_second_banner {
            p {
              font-size: 30px;
              width: 100% !important;
              z-index: 1;
              line-height: 42px;
              top: 0% !important;
              padding-left: 0px !important;
              margin-top: 10px !important;

              span {
                font-size: 30px;
              }

              .third_line {
                font-size: 30px;
              }

              button {
                width: 170px;
                height: 40px;
                font-size: 30px;
                margin-top: 10px;
              }
            }

            .main_img {
              right: 0;
              top: 33%;
              width: 100%;
              height: 340px;
              object-fit: cover;
              left: 0;
              margin: auto;
            }
          }

          .frontpage_banner {
            p {
              margin-top: 16px !important;
            }
          }
        }
      }

      .Ultra_Pro_section {
        padding: 0px 10px 20px;
        background-position: 100% 11%;
        background-size: auto 583px;
        margin-bottom: 0 !important;

        .Ultra_Pro_title_row {
          .Top_Cont {
            width: 400px;

            p {
              font-size: 35px;
            }
          }
        }

        .Ultra_Pro_content_row {
          grid-template-columns: 100%;
          display: flex;
          flex-wrap: wrap-reverse;
          justify-content: center;

          .Ultra_Pro_col_01 {
            grid-template-columns: 100%;
            display: block;

            .Ultra_Pro_btn_block {
              display: flex;
              width: 360px;
              padding: 25px 0;

              .btn_outter {
                margin: auto !important;

                a.btn_inner {
                  padding: 3px;
                  width: 165px;

                  img {
                    width: auto;
                    height: 32px;
                  }

                  span.btn_inner_text {
                    font-size: 20px;
                  }
                }
              }
            }

            .Ultra_Pro_content_block {
              .Ultra_Pro_min_font {
                font-size: 25px;
                padding-bottom: 0px;
              }

              .Ultra_Pro_big_title {
                font-size: 31px;
                margin-bottom: 20px;

                &:after {
                  width: 135px;
                }
              }

              ul.Ultra_Pro_text {
                padding-left: 40px;

                li.Ultra_Pro_li {
                  font-size: 17px;
                  padding-bottom: 0;
                  line-height: 0.8;
                }
              }
            }
          }

          .Ultra_Pro_col_02 {
            display: flex;
            width: 430px;
            margin: auto;

            .ultra_mobile_cell {
              width: auto;
              height: 433px;
              margin: 20px auto 0px auto;
            }

            img.ab_ultra_onlinelaunching {
              right: 5%;
              width: auto;
              height: 57px;
            }

            img.ab_ultra_play {
              right: 11%;
              top: 0%;
              width: auto;
              height: 55px;
            }

            img.ab_ultra_bell {
              left: 3%;
              width: auto;
              height: 55px;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 481px) and (max-width: 580px) {
  .frontpage_wrapper {
    .frontpage_main_section {
      .front-story-wrapper {
        width: 100%;
        padding: 5px 10px;

        .front-story-heading {
          font-size: 28px;
        }

        .front-story-txt {
          padding: 10px 0px;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .frontpage_slider_section {
        .frontpage_slider {
          padding-top: 72px;

          .second_slider {
            background-position: 21% !important;

            p {
              right: 0;
              width: 100% !important;
            }
          }

          .slick-next {
            top: 39% !important;
            right: 22px !important;

            &:before {
              font-size: 38px !important;
            }
          }

          .slick-prev {
            left: 4px;
            top: 39%;

            &:before {
              font-size: 38px !important;
            }
          }

          .slick-arrow {
            display: block !important;
          }

          .frontpage_mb_banner {
            // background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/front-mb-banner.png);
            width: 100%;
            height: 507px;
            background-position: center;
          }

          .frontpage_banner {
            .first_banner {
              right: 0;
              top: 62%;
              width: 450px;
              margin: auto;
              left: 0;
            }

            p {
              font-size: 30px;
              padding-left: 0;
              width: 82%;
              top: 3%;
              position: relative;
              text-align: center;
              margin: auto;
              margin-top: 53px;
            }

            .second_banner_img {
              display: block;
              width: 477px;
              top: 40%;
            }
          }

          .about_content {
            p {
              font-size: 14px;
              width: 98%;
              line-height: 20px;
              padding: 10px 0px;

              span {
                font-weight: 600;
              }
            }
          }

          .third_mb_banner {
            background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/home_mb.jpg) !important;
            // background-image: url("../../assets/img/front/home_mb.jpg") !important;
            width: 100%;
            height: 507px;
          }

          .third_slider {
            background-image: none;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 100%;

            p {
              width: 70% !important;
              font-size: 34px !important;
              line-height: 33px;
              margin: 0;
              margin-top: 0 !important;

              span {
                font-size: 34px;
              }
            }
          }
        }
      }

      .free_demo_class {
        .Card_Container {
          padding: 5px;
          width: 100%;
          border: none;
          background-color: transparent;
          border-radius: 0px;
          margin: 10px auto 0px auto;

          .cards_row {
            grid-gap: 0px 0px !important;
            gap: 0px 0px !important;

            .Common_Card_Main_Container {
              width: 49%;
              margin: 0 0.5%;

              .Common_Card_Container {
                width: 100%;
                border-radius: 30px;

                .Card_Image_Container {
                  padding-top: 0px;
                  height: 105px;

                  .Card_Img {
                    width: 100%;
                    height: 100px;
                    border-radius: 25px 25px 0 0;
                  }

                  .mostReviewedWrapper {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .Explore_More_Btn {
          button {
            width: 100%;
            font-size: 20px;
          }
        }

        .slick-arrow {
          display: block !important;
        }

        .slick-prev {
          left: -52px;
        }

        .slick-next {
          right: -30px;
        }

        .popular_class_box_section {
          .class_title {
            margin: 25px 0px 20px 0px;

            .Blue_Wawe {
              width: 200px;
              height: 14px;
            }

            h2 {
              font-size: 44px;
            }
          }
        }
      }

      .free_class_title {
        padding: 8px 21px;
        border-radius: 0px;
        width: 100%;

        h2 {
          font-size: 40px;
        }
      }

      .category_main_section {
        .category_row {
          .category_container_section {
            width: 95%;
            padding: 15px 10px;
            margin: 25px auto 25px auto;

            .category_container {
              display: flex;
              grid-gap: 5px;
              gap: 5px;

              .category_box {
                width: 32%;
                height: auto;
                border-radius: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .category_name {
                  p {
                    font-size: 15px;
                    width: 100%;
                    padding-bottom: 5px;
                  }
                }

                .category_image {
                  width: 55px;
                  height: 55px;
                  border-radius: 12px;
                  margin-top: 10px;

                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
              }
            }
          }

          .category_title {
            h2 {
              font-size: 44px;
              margin-top: 15px;
            }

            .Blue_Wawe {
              width: 180px;
              height: 15px;
            }
          }
        }
      }

      .site_banner_section {
        .slick-next {
          right: 0px !important;

          &::before {
            font-size: 29px !important;
          }
        }

        .slick-prev {
          left: -10px;

          &::before {
            font-size: 29px !important;
          }
        }

        .site_banner_row {
          padding: 0px 20px;

          .site_banner {
            background-image: none;
            background-image: linear-gradient(to bottom,
                #58ccde,
                #51c5d9,
                #4abfd5,
                #42b8d0,
                #3bb2cb);
            border-radius: 50px;
            height: 163px;

            h1 {
              font-size: 25px;
              width: 78%;
            }
          }
        }
      }

      .sign_up_section {
        .sign_up_grid_row {
          .sign_up_grid {
            display: block;

            .sign_up_grid_item {
              width: 385px;
              height: 323px;
              border-radius: 20px;
              margin: auto;
              margin-top: 116px;

              .title {
                width: 69%;
                font-size: 17px;
                margin-top: 0% !important;
                padding-top: 100px;
                text-align: center;
                margin: auto;
              }

              p {
                font-size: 15px;
                width: 90%;
                margin-top: 12px;
                line-height: 24px;
              }

              &:nth-child(3) img {
                width: 182px;
                top: -65px !important;
                left: 25%;
              }

              &:nth-child(2) img {
                width: 196px;
                top: -85px !important;
                left: 24%;
              }

              img {
                width: 225px;
                top: -98px;
                left: 22%;
              }

              .sign_up_btn {
                button {
                  width: 195px;
                  font-size: 16px;
                  height: 53px;
                  bottom: -23px;
                  letter-spacing: 0.3px;
                }
              }
            }
          }
        }
      }

      .find_out_body {
        display: block;
        padding: 33px 0px 38px 0px;

        .find_out_section {
          .find_out_row {
            .find_out_col {
              text-align: center;

              .find_out_left {
                gap: 0 12px;
                padding: 0px 12px;

                .active-tabs {
                  &::before {
                    width: 7px;
                    height: 43px;
                    top: 9px;
                    left: -15px;
                    display: none;
                  }
                }

                button {
                  margin: auto;
                  margin-bottom: 15px !important;
                  width: 418px;
                  height: 60px;
                  font-size: 14px;
                  padding: 0px 10px;
                }
              }
            }
          }
        }

        .find_out_more_section {
          .find_out_more_row {
            .find_out_more_col {
              .Card_Img {
                width: 93%;
                height: 253px;
                margin: auto;
                display: flex;
              }
            }
          }
        }
      }

      .teach_fifth_section_row {
        .main_fifth_section {
          .support_main_section_row {
            .support_section_div {
              padding: 20px 20px 20px 20px;
              display: block;
              margin-top: 12px;

              .left_section {
                h1 {
                  font-size: 20px;
                  line-height: 30px;
                  margin-right: 135px;
                  width: 100%;
                  text-align: center;
                }
              }

              .right_section {
                .first {
                  font-size: 17px;
                  width: 100%;
                  text-align: center;
                }

                .email_call_section {
                  // display: flex;
                }

                .email_section {
                  margin: 6px auto;

                  .mail_box {
                    width: 80px;
                    height: 55px;
                    border-radius: 12px;
                    justify-content: flex-start;

                    img {
                      width: 35px !important;
                    }
                  }
                }

                h1 {
                  font-size: 20px;
                  line-height: 30px;
                  text-align: center;
                }
              }
            }
          }

          .teaching_btn button {
            width: 185px;
            height: 43px;
            font-size: 18px;
          }

          .title_section {
            h1 {
              font-size: 24px;
              margin-bottom: 20px;
            }
          }

          .fifth_grid_section {
            padding: 15px 10px 56px 10px;
            gap: 20px 8px;

            .last_box_div {
              .last_box_content {
                &:nth-child(1) h1 {
                  font-size: 22px !important;
                }

                .second {
                  font-size: 32px !important;
                }
              }
            }

            .grid_items_box {
              width: 365px;
              height: auto;

              .category_content {
                padding-left: 17px;

                span {
                  font-size: 17px;
                }

                h2 {
                  margin-bottom: 6px;
                  font-size: 20px;
                }
              }

              .category_icon {
                width: 60px;
                height: 60px;
                border-radius: 16px;

                .category {
                  width: 44px !important;
                  height: 44px !important;
                }

                img {
                  width: 38px;
                  height: 38px;
                }
              }
            }
          }
        }
      }
    }

    .enquiry_section_row {
      .enquiry_section_col {
        h1 {
          font-size: 36px !important;
        }

        .main_form {
          width: 95% !important;
          padding: 26px 22px !important;
        }

        .main_form {
          .enquiry_filed {
            textarea {
              height: 145px;
            }
          }

          .flex_input {
            input {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 581px) and (max-width: 767px) {
  .frontpage_wrapper {
    .frontpage_main_section {
      .front-story-wrapper {
        width: 100%;
        padding: 5px 10px;
        height: 600px;
        overflow: auto;

        .front-story-heading {
          font-size: 28px;
        }

        .front-story-txt {
          padding: 10px 0px;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .frontpage_slider_section {
        .frontpage_slider {
          padding-top: 72px;

          .second_slider {
            background-position: 21% !important;

            p {
              right: 0;
              width: 100% !important;
            }
          }

          .slick-next {
            top: 39% !important;
            right: 22px !important;

            &:before {
              font-size: 38px !important;
            }
          }

          .slick-prev {
            left: 4px;
            top: 39%;

            &:before {
              font-size: 38px !important;
            }
          }

          .slick-arrow {
            display: block !important;
          }

          .frontpage_mb_banner {
            // background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/front-mb-banner.png);
            width: 100%;
            height: 507px;
            background-position: center;
          }

          .frontpage_banner {
            .first_banner {
              right: 0;
              top: 62%;
              width: 450px;
              margin: auto;
              left: 0;
            }

            p {
              font-size: 33px;
              padding-left: 0;
              width: 82%;
              top: 3%;
              position: relative;
              text-align: center;
              margin: auto;
              margin-top: 53px;
            }

            .second_banner_img {
              display: block;
              width: 477px;
              top: 40%;
            }
          }

          .about_content {
            p {
              font-size: 14px;
              width: 98%;
              line-height: 20px;
              padding: 10px 0px;

              span {
                font-weight: 600;
              }
            }
          }

          .third_mb_banner {
            background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/home_mb.jpg) !important;
            // background-image: url("../../assets/img/front/home_mb.jpg") !important;
            width: 100%;
            height: 507px;
          }

          .third_slider {
            background-image: none;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 100%;

            p {
              width: 70% !important;
              font-size: 34px !important;
              line-height: 33px;
              margin: 0;
              margin-top: 0 !important;

              span {
                font-size: 34px;
              }
            }
          }
        }
      }

      .free_demo_class {
        .Card_Container {
          padding: 5px;
          width: 100%;
          border: none;
          background-color: transparent;
          border-radius: 0px;
          margin: 10px auto 0px auto;

          .cards_row {
            grid-gap: 0px 0px !important;
            gap: 0px 0px !important;

            .Common_Card_Main_Container {
              width: 32%;
              margin: 0 0.5%;

              .Common_Card_Container {
                width: 100%;
                border-radius: 30px;

                .Card_Image_Container {
                  padding-top: 0px;
                  height: 105px;

                  .Card_Img {
                    width: 100%;
                    height: 100px;
                    border-radius: 25px 25px 0 0;
                  }

                  .mostReviewedWrapper {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .Explore_More_Btn {
          button {
            width: 100%;
            font-size: 20px;
          }
        }

        .slick-arrow {
          display: block !important;
        }

        .slick-prev {
          left: -52px;
        }

        .slick-next {
          right: -30px;
        }

        .popular_class_box_section {
          .class_title {
            margin: 25px 0px 20px 0px;

            .Blue_Wawe {
              width: 200px;
              height: 14px;
            }

            h2 {
              font-size: 44px;
            }
          }
        }
      }

      .free_class_title {
        padding: 8px 21px;
        border-radius: 0px;
        width: 100%;

        h2 {
          font-size: 40px;
        }
      }

      .category_main_section {
        .category_row {
          .category_container_section {
            width: 95%;
            padding: 15px 10px;
            margin: 25px auto 25px auto;

            .category_container {
              display: flex;
              grid-gap: 5px;
              gap: 5px;

              .category_box {
                width: 32%;
                height: auto;
                border-radius: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .category_name {
                  p {
                    font-size: 15px;
                    width: 100%;
                    padding-bottom: 5px;
                  }
                }

                .category_image {
                  width: 55px;
                  height: 55px;
                  border-radius: 12px;
                  margin-top: 10px;

                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
              }
            }
          }

          .category_title {
            h2 {
              font-size: 44px;
              margin-top: 15px;
            }

            .Blue_Wawe {
              width: 180px;
              height: 15px;
            }
          }
        }
      }

      .site_banner_section {
        .slick-next {
          right: 0px !important;

          &::before {
            font-size: 29px !important;
          }
        }

        .slick-prev {
          left: -10px;

          &::before {
            font-size: 29px !important;
          }
        }

        .site_banner_row {
          padding: 0px 20px;

          .site_banner {
            background-image: none;
            background-image: linear-gradient(to bottom,
                #58ccde,
                #51c5d9,
                #4abfd5,
                #42b8d0,
                #3bb2cb);
            border-radius: 50px;
            height: 163px;

            h1 {
              font-size: 25px;
              width: 78%;
            }
          }
        }
      }

      .sign_up_section {
        .sign_up_grid_row {
          .sign_up_grid {
            display: block;

            .sign_up_grid_item {
              width: 385px;
              height: 323px;
              border-radius: 20px;
              margin: auto;
              margin-top: 116px;

              .title {
                width: 69%;
                font-size: 17px;
                margin-top: 0% !important;
                padding-top: 100px;
                text-align: center;
                margin: auto;
              }

              p {
                font-size: 15px;
                width: 90%;
                margin-top: 12px;
                line-height: 24px;
              }

              &:nth-child(3) img {
                width: 182px;
                top: -65px !important;
                left: 25%;
              }

              &:nth-child(2) img {
                width: 196px;
                top: -85px !important;
                left: 24%;
              }

              img {
                width: 225px;
                top: -98px;
                left: 22%;
              }

              .sign_up_btn {
                button {
                  width: 195px;
                  font-size: 16px;
                  height: 53px;
                  bottom: -23px;
                  letter-spacing: 0.3px;
                }
              }
            }
          }
        }
      }

      .find_out_body {
        display: block;
        padding: 33px 0px 38px 0px;

        .find_out_section {
          .find_out_row {
            .find_out_col {
              text-align: center;

              .find_out_left {
                gap: 0 12px;
                padding: 0px 12px;

                .active-tabs {
                  &::before {
                    width: 7px;
                    height: 43px;
                    top: 9px;
                    left: -15px;
                    display: none;
                  }
                }

                button {
                  margin: auto;
                  margin-bottom: 15px !important;
                  width: 418px;
                  height: 60px;
                  font-size: 14px;
                  padding: 0px 10px;
                }
              }
            }
          }
        }

        .find_out_more_section {
          .find_out_more_row {
            .find_out_more_col {
              .Card_Img {
                width: 443px;
                height: 253px;
                margin: auto;
                display: flex;
              }
            }
          }
        }
      }

      .teach_fifth_section_row {
        .main_fifth_section {
          .support_main_section_row {
            .support_section_div {
              padding: 20px 20px 20px 20px;
              display: block;
              margin-top: 12px;

              .left_section {
                h1 {
                  font-size: 20px;
                  line-height: 30px;
                  margin-right: 135px;
                  width: 100%;
                  text-align: center;
                }
              }

              .right_section {
                .first {
                  font-size: 17px;
                  width: 100%;
                  text-align: center;
                }

                .email_call_section {
                  // display: flex;
                }

                .email_section {
                  margin: 6px auto;

                  .mail_box {
                    width: 80px;
                    height: 55px;
                    border-radius: 12px;
                    justify-content: flex-start;

                    img {
                      width: 35px !important;
                    }
                  }
                }

                h1 {
                  font-size: 20px;
                  line-height: 30px;
                  text-align: center;
                }
              }
            }
          }

          .teaching_btn button {
            width: 185px;
            height: 43px;
            font-size: 18px;
          }

          .title_section {
            h1 {
              font-size: 24px;
              margin-bottom: 20px;
            }
          }

          .fifth_grid_section {
            padding: 15px 10px 56px 10px;
            gap: 20px 8px;

            .last_box_div {
              .last_box_content {
                &:nth-child(1) h1 {
                  font-size: 22px !important;
                }

                .second {
                  font-size: 32px !important;
                }
              }
            }

            .grid_items_box {
              width: 365px;
              height: auto;

              .category_content {
                padding-left: 17px;

                span {
                  font-size: 17px;
                }

                h2 {
                  margin-bottom: 6px;
                  font-size: 20px;
                }
              }

              .category_icon {
                width: 60px;
                height: 60px;
                border-radius: 16px;

                .category {
                  width: 44px !important;
                  height: 44px !important;
                }

                img {
                  width: 38px;
                  height: 38px;
                }
              }
            }
          }
        }
      }
    }

    .enquiry_section_row {
      .enquiry_section_col {
        h1 {
          font-size: 36px !important;
        }

        .main_form {
          width: 95% !important;
          padding: 26px 22px !important;
        }

        .main_form {
          .enquiry_filed {
            textarea {
              height: 145px;
            }
          }

          .flex_input {
            input {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 980px) {
  .frontpage_wrapper {
    .frontpage_main_section {
      .free_demo_class {
        .popular_class_box_section {
          .class_title {
            h2 {
              font-size: 44px;
            }
          }
        }
      }
    }
  }

  .frontpage_wrapper {
    .frontpage_main_section {
      .front-story-wrapper {
        width: 100%;
        padding: 10px 20px;

        .front-story-heading {
          font-size: 30px;
        }

        .front-story-txt {
          padding: 10px 0px;
          font-size: 15px;
          line-height: 22px;
        }
      }

      .frontpage_slider_section {
        .frontpage_slider {
          padding-top: 72px;

          .new_banner {
            .new_banner_img {
              top: auto !important;
              bottom: 0 !important;
              width: 935px;
              height: 320px;
              left: 0;
              margin: auto;
              object-fit: cover;
            }

            p {
              span {
                font-size: 33px;
                margin-top: 17px !important;
                display: block;
                width: fit-content;
                margin: auto;
              }
            }
          }

          .festival_banner {

            .decoration_div,
            .decoration-div {
              width: 70px;
            }

            .main_img {
              right: 0;
            }

            p {
              line-height: 48px;
              font-size: 36px !important;
              width: 80% !important;
              padding-left: 123px;
              top: 4% !important;
              margin-top: 0 !important;

              span {
                font-size: 38px;
              }

              .main_content {
                margin-top: 0px !important;
                margin-bottom: 0px !important;
              }
            }

            span {
              font-size: 80px;
            }

            .main_img {
              width: 500px;
              top: 51%;
              right: 0;
              left: 0;
              margin: auto;
            }
          }

          .festival_second_banner {
            p {
              width: 100% !important;
              z-index: 1;
              line-height: 56px;
              top: 0% !important;
              padding-left: 0px !important;
              margin-top: 10px !important;

              span {
                font-size: 35px;
              }

              .third_line {
                font-size: 36px;
              }

              button {
                width: 180px;
                height: 45px;
                font-size: 35px;
              }
            }

            .main_img {
              right: 0;
              top: 33%;
              width: 100%;
              height: 340px;
              object-fit: cover;
              left: 0;
              margin: auto;
            }
          }

          .second_slider {
            background-position: 21% !important;

            p {
              right: 0;
              width: 100% !important;
            }
          }

          .slick-next {
            top: 39% !important;
            right: 22px !important;

            &:before {
              font-size: 38px !important;
            }
          }

          .slick-prev {
            left: 4px;
            top: 39%;

            &:before {
              font-size: 38px !important;
            }
          }

          .slick-arrow {
            display: block !important;
          }

          .frontpage_mb_banner {
            // background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/front-mb-banner.png);
            width: 100%;
            height: 507px;
            background-position: center;
          }

          .frontpage_banner {
            .first_banner {
              right: 0;
              top: 62%;
              width: 450px;
              margin: auto;
              left: 0;
            }

            p {
              font-size: 40px;
              padding-left: 0;
              width: 82%;
              top: 3%;
              position: relative;
              text-align: center;
              margin: auto;
              margin-top: 20px;
            }

            .meta-verse {
              font-size: 40px !important;
            }

            .try-beta-version {
              font-size: 30px;
              top: 25%;
            }

            .second_banner_img {
              display: block;
              width: 477px;
              top: 40%;
            }
          }

          .about_content {
            p {
              font-size: 14px;
              width: 98%;
              line-height: 20px;
              padding: 10px 0px;

              span {
                font-weight: 600;
              }
            }
          }

          .third_mb_banner {
            background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/home_mb.jpg) !important;
            // background-image: url("../../assets/img/front/home_mb.jpg") !important;
            width: 100%;
            height: 507px;
          }

          .third_slider {
            background-image: none;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 100%;
            background-position: center;

            p {
              width: 70% !important;
              font-size: 34px !important;
              line-height: 33px;
              margin: 0;
              margin-top: 0 !important;

              span {
                font-size: 34px;
              }
            }
          }
        }
      }

      .free_demo_class {
        .Card_Container {
          padding: 5px;
          width: 100%;
          border: none;
          background-color: transparent;
          border-radius: 0px;
          margin: 10px auto 0px auto;

          .cards_row {
            grid-gap: 0px 0px !important;
            gap: 0px 0px !important;

            .Common_Card_Main_Container {
              width: 32% !important;
              margin: 0 0.5%;

              .Common_Card_Container {
                width: 100%;
                border-radius: 22px;

                .Card_Image_Container {
                  padding-top: 0px;
                  height: 105px;

                  .Card_Img {
                    width: 100%;
                    height: 100px;
                    border-radius: 20px 20px 0 0;
                  }

                  .mostReviewedWrapper {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .Explore_More_Btn {
          button {
            width: 100%;
            font-size: 20px;
          }
        }

        .slick-arrow {
          display: block !important;
        }

        .slick-prev {
          left: -52px;
        }

        .slick-next {
          right: -30px;
        }

        .popular_class_box_section {
          .class_title {
            margin: 25px 0px 20px 0px;

            .Blue_Wawe {
              width: 200px;
              height: 14px;
            }

            h1 {
              font-size: 44px;
            }

            h2 {
              font-size: 44px;
            }
          }
        }
      }

      .free_class_title {
        padding: 8px 21px;
        border-radius: 0px;
        width: 100%;

        h2 {
          font-size: 40px;
        }
      }

      .category_main_section {
        .category_row {
          .category_container_section {
            width: 95%;
            padding: 15px 10px;
            margin: 25px auto 25px auto;

            .category_container {
              display: flex;
              grid-gap: 5px;
              gap: 5px;

              .category_box {
                width: 24%;
                height: auto;
                border-radius: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .category_name {
                  p {
                    font-size: 15px;
                    width: 100%;
                    padding-bottom: 5px;
                  }
                }

                .category_image {
                  width: 55px;
                  height: 55px;
                  border-radius: 12px;
                  margin-top: 10px;

                  img {
                    width: 38px;
                    height: 38px;
                  }
                }
              }
            }
          }

          .category_title {
            h2 {
              font-size: 44px;
              margin-top: 15px;
            }

            .Blue_Wawe {
              width: 180px;
              height: 15px;
            }
          }
        }
      }

      .site_banner_section {
        .slick-next {
          right: 0px !important;

          &::before {
            font-size: 29px !important;
          }
        }

        .slick-prev {
          left: -10px;

          &::before {
            font-size: 29px !important;
          }
        }

        .site_banner_row {
          padding: 0px 20px;

          .site_banner {
            background-image: none;
            background-image: linear-gradient(to bottom,
                #58ccde,
                #51c5d9,
                #4abfd5,
                #42b8d0,
                #3bb2cb);
            border-radius: 50px;
            height: 163px;

            h1 {
              font-size: 25px;
              width: 78%;
            }
          }
        }
      }

      .Ultra_Pro_section {
        padding: 0px 15px 0px;
        background-position: 42% 16%;
        background-size: auto 627px;

        .Ultra_Pro_title_row {
          .Top_Cont {
            width: 480px;

            p {
              font-size: 40px;
            }
          }
        }

        .Ultra_Pro_content_row {
          grid-template-columns: 100%;
          display: flex;
          flex-wrap: wrap-reverse;

          .Ultra_Pro_col_01 {
            grid-template-columns: 67% 33%;
            margin-top: 20px;

            .Ultra_Pro_btn_block {
              display: block;
              width: 360px;
              padding: 25px 0;

              .btn_outter:nth-child(1) {
                margin-bottom: 15px !important;
              }

              .btn_outter {
                margin: auto !important;

                a.btn_inner {
                  padding: 3px;
                  width: 165px;

                  img {
                    width: auto;
                    height: 40px;
                  }

                  span.btn_inner_text {
                    font-size: 20px;
                  }
                }
              }
            }

            .Ultra_Pro_content_block {
              .Ultra_Pro_min_font {
                font-size: 25px;
                padding-bottom: 0px;
              }

              .Ultra_Pro_big_title {
                font-size: 35px;
                margin-bottom: 20px;

                &:after {
                  width: 135px;
                }
              }

              ul.Ultra_Pro_text {
                padding-left: 40px;

                li.Ultra_Pro_li {
                  font-size: 17px;
                  padding-bottom: 0;
                  line-height: 0.8;
                  margin: 10px 0px;
                }
              }
            }
          }

          .Ultra_Pro_col_02 {
            display: flex;
            width: 480px;
            margin: auto;

            .ultra_mobile_cell {
              width: auto;
              height: 430px;
              margin: auto;
            }

            img.ab_ultra_onlinelaunching {
              right: -6%;
              width: auto;
              height: 57px;
            }

            img.ab_ultra_play {
              right: 11%;
              top: 0%;
              width: auto;
              height: 55px;
            }

            img.ab_ultra_bell {
              left: 3%;
              width: auto;
              height: 55px;
            }
          }
        }
      }

      .sign_up_section {
        .sign_up_grid_row {
          margin: 7% 7px 47px 7px;

          .sign_up_grid {
            // display: block;
            gap: 0 9px;

            .sign_up_grid_item {
              width: 385px;
              height: 323px;
              border-radius: 20px;
              margin: auto;
              margin-top: 116px;

              .title {
                width: 69%;
                font-size: 17px;
                margin-top: -8% !important;
                padding-top: 100px;
                text-align: center;
                margin: auto;
              }

              p {
                font-size: 15px;
                width: 90%;
                margin-top: 12px;
                line-height: 23px;
              }

              &:nth-child(3) img {
                width: 146px;
                top: -62px !important;
                left: 22%;
              }

              &:nth-child(2) img {
                width: 172px;
                top: -83px !important;
                left: 13%;
              }

              img {
                width: 180px;
                top: -84px;
                left: 10%;
              }

              .sign_up_btn {
                button {
                  width: 180px;
                  font-size: 15px;
                  height: 46px;
                  bottom: -23px;
                  letter-spacing: 0.3px;
                }
              }
            }
          }
        }
      }

      .find_out_body {
        display: block;
        padding: 33px 0px 38px 0px;

        .find_out_section {
          .find_out_row {
            .find_out_col {
              text-align: center;

              h2 {
                padding-left: 0;
              }

              .find_out_left {
                gap: 0 12px;
                padding: 0px 12px;

                .active-tabs {
                  &::before {
                    width: 7px;
                    height: 43px;
                    top: 9px;
                    left: -15px;
                    display: none;
                  }
                }

                button {
                  margin: auto;
                  margin-bottom: 15px !important;
                  width: 418px;
                  height: 60px;
                  font-size: 14px;
                  padding: 0px 10px;
                }
              }
            }
          }
        }

        .find_out_more_section {
          .find_out_more_row {
            .find_out_more_col {
              .Card_Img {
                width: 444px;
                height: 253px;
                margin: auto;
                display: flex;
              }
            }
          }
        }
      }

      .teach_fifth_section_row {
        .main_fifth_section {
          .support_main_section_row {
            .support_section_div {
              padding: 20px 20px 20px 20px;
              display: block;
              margin-top: 12px;

              .left_section {
                h1 {
                  font-size: 20px;
                  line-height: 30px;
                  margin-right: 135px;
                  width: 100%;
                  text-align: center;
                }
              }

              .right_section {
                .first {
                  font-size: 17px;
                  width: 100%;
                  text-align: center;
                }

                .email_call_section {
                  // display: flex;
                }

                .email_section {
                  margin: 6px auto;

                  .mail_box {
                    width: 80px;
                    height: 55px;
                    border-radius: 12px;
                    justify-content: flex-start;

                    img {
                      width: 35px !important;
                    }
                  }
                }

                h1 {
                  font-size: 20px;
                  line-height: 30px;
                  text-align: center;
                }
              }
            }
          }

          .teaching_btn button {
            width: 185px;
            height: 43px;
            font-size: 18px;
          }

          .title_section {
            h1 {
              font-size: 24px;
              margin-bottom: 20px;
            }
          }

          .fifth_grid_section {
            padding: 15px 10px 56px 10px;
            gap: 20px 8px;

            .last_box_div {
              .last_box_content {
                &:nth-child(1) h1 {
                  font-size: 22px !important;
                }

                .second {
                  font-size: 32px !important;
                }
              }
            }

            .grid_items_box {
              width: 365px;
              height: auto;

              .category_content {
                padding-left: 17px;

                span {
                  font-size: 17px;
                }

                h2 {
                  margin-bottom: 6px;
                  font-size: 20px;
                }
              }

              .category_icon {
                width: 60px;
                height: 60px;
                border-radius: 16px;

                .category {
                  width: 44px !important;
                  height: 44px !important;
                }

                img {
                  width: 38px;
                  height: 38px;
                }
              }
            }
          }
        }
      }
    }

    .enquiry_section_row {
      .enquiry_section_col {
        h1 {
          font-size: 46px !important;
        }

        .main_form {
          .enquiry_filed {
            textarea {
              height: 164px;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 981px) and (max-width: 1024px) {
  .frontpage_wrapper {
    .frontpage_main_section {
      .front-story-wrapper {
        width: 100%;
        padding: 5px 10px;

        .front-story-heading {
          font-size: 32px;
        }

        .front-story-txt {
          padding: 10px 0px;
          font-size: 15px;
          line-height: 22px;
        }
      }

      .frontpage_slider_section {
        .frontpage_slider {
          padding-top: 72px;

          .new_banner {
            .new_banner_img {
              top: auto !important;
              bottom: 0 !important;
              width: 935px;
              height: 320px;
              left: 0;
              margin: auto;
              object-fit: cover;
            }

            p {
              span {
                font-size: 33px;
                margin-top: 17px !important;
                display: block;
                width: fit-content;
                margin: auto;
              }
            }

            .try-beta-version {
              top: 30%;
              font-size: 30px;
            }
          }

          .festival_banner {

            .decoration_div,
            .decoration-div {
              width: 85px;
            }

            .main_img {
              right: 0;
            }

            p {
              line-height: 48px;
              font-size: 36px !important;
              width: 60% !important;
              padding-left: 123px;
              top: 0% !important;
              margin-top: 0 !important;

              span {
                font-size: 45px;
              }

              .main_content {
                margin-top: 0px !important;
                margin-bottom: 0px !important;
              }
            }

            span {
              font-size: 80px;
            }

            .main_img {
              width: 500px;
              top: 51%;
              right: 0;
              left: 0;
              margin: auto;
            }
          }

          .festival_second_banner {
            p {
              width: 100% !important;
              z-index: 1;
              line-height: 56px;
              top: 0% !important;
              padding-left: 0px !important;
              margin-top: 10px !important;

              span {
                font-size: 35px;
              }

              .third_line {
                font-size: 36px;
              }

              button {
                width: 180px;
                height: 45px;
                font-size: 35px;
              }
            }

            .main_img {
              right: 0;
              top: 121px;
              width: 100%;
              height: 390px;
              object-fit: cover;
              left: 0;
              margin: auto;
            }
          }

          .second_slider {
            background-position: 21% !important;

            p {
              right: 0;
              width: 100% !important;
            }
          }

          .slick-next {
            top: 39% !important;
            right: 22px !important;

            &:before {
              font-size: 38px !important;
            }
          }

          .slick-prev {
            left: 4px;
            top: 39%;

            &:before {
              font-size: 38px !important;
            }
          }

          .slick-arrow {
            display: block !important;
          }

          .frontpage_mb_banner {
            // background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/front-mb-banner.png);
            width: 100%;
            height: 507px;
            background-position: center;
          }

          .frontpage_banner {
            .first_banner {
              right: 0;
              top: 62%;
              width: 450px;
              margin: auto;
              left: 0;
            }

            p {
              font-size: 40px;
              padding-left: 0;
              width: 82%;
              top: 3%;
              position: relative;
              text-align: center;
              margin: auto;
              margin-top: 20px;
            }

            .second_banner_img {
              display: block;
              width: 477px;
              top: 40%;
            }
          }

          .about_content {
            p {
              font-size: 18px;
              width: 75%;
              line-height: 20px;
              padding: 15px 0px;

              span {
                font-weight: 600;
              }
            }
          }

          .third_mb_banner {
            background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/front/home_mb.jpg) !important;
            // background-image: url("../../assets/img/front/home_mb.jpg") !important;
            width: 100%;
            height: 507px;
          }

          .third_slider {
            background-image: none;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 100%;
            background-position: center;

            p {
              width: 70% !important;
              font-size: 34px !important;
              line-height: 33px;
              margin: 0;
              margin-top: 0 !important;

              span {
                font-size: 34px;
              }
            }
          }
        }
      }

      .free_demo_class {
        .Card_Container {
          padding: 5px;
          width: 100%;
          border: none;
          background-color: transparent;
          border-radius: 0px;
          margin: 10px auto 0px auto;

          .cards_row {
            grid-gap: 10px 0px !important;
            gap: 10px 0px !important;

            .Common_Card_Main_Container {
              width: 24%;
              margin: 0 0.5%;

              .Common_Card_Container {
                width: 100%;
                border-radius: 30px;

                .Card_Image_Container {
                  padding-top: 0px;
                  height: 105px;

                  .Card_Img {
                    width: 100%;
                    height: 100px;
                    border-radius: 25px 25px 0 0;
                  }

                  .mostReviewedWrapper {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .Explore_More_Btn {
          padding-top: 10px;

          button {
            width: 100%;
            font-size: 20px;
          }
        }

        .slick-arrow {
          display: block !important;
        }

        .slick-prev {
          left: -52px;
        }

        .slick-next {
          right: -30px;
        }

        .popular_class_box_section {
          .class_title {
            margin: 25px 0px 20px 0px;

            .Blue_Wawe {
              width: 200px;
              height: 14px;
            }

            h1 {
              font-size: 44px;
            }
          }
        }
      }

      .free_class_title {
        padding: 8px 21px;
        border-radius: 0px;
        width: 100%;

        h2 {
          font-size: 40px;
        }
      }

      .category_main_section {
        .category_row {
          .category_container_section {
            width: 95%;
            padding: 15px 10px;
            margin: 25px auto 25px auto;

            .category_container {
              display: flex;
              grid-gap: 5px;
              gap: 5px;

              .category_box {
                width: 19%;
                height: auto;
                border-radius: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .category_name {
                  p {
                    font-size: 15px;
                    width: 100%;
                    padding-bottom: 5px;
                  }
                }

                .category_image {
                  width: 55px;
                  height: 55px;
                  border-radius: 12px;
                  margin-top: 10px;

                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
              }
            }
          }

          .category_title {
            h2 {
              font-size: 44px;
              margin-top: 15px;
            }

            .Blue_Wawe {
              width: 180px;
              height: 15px;
            }
          }
        }
      }

      .site_banner_section {
        .slick-next {
          right: 0px !important;

          &::before {
            font-size: 29px !important;
          }
        }

        .slick-prev {
          left: -10px;

          &::before {
            font-size: 29px !important;
          }
        }

        .site_banner_row {
          padding: 0px 20px;

          .site_banner {
            background-image: none;
            background-image: linear-gradient(to bottom,
                #58ccde,
                #51c5d9,
                #4abfd5,
                #42b8d0,
                #3bb2cb);
            border-radius: 50px;
            height: 163px;

            h1 {
              font-size: 25px;
              width: 78%;
            }
          }
        }
      }

      .Ultra_Pro_section {
        padding: 0px 60px 80px;
        background-position: 115% 25px;
        background-size: auto 668px;

        .Ultra_Pro_title_row {
          .Top_Cont {
            width: 492px;

            p {
              font-size: 45px;
            }
          }
        }

        .Ultra_Pro_content_row {
          grid-template-columns: 70% 30%;

          .Ultra_Pro_col_01 {
            grid-template-columns: 70% 30%;

            .Ultra_Pro_btn_block .btn_outter {
              a.btn_inner {
                padding: 3px;
                width: 190px;
              }

              a.btn_inner img {
                width: auto;
                height: 46px;
              }
            }

            .Ultra_Pro_content_block {
              .Ultra_Pro_min_font {
                font-size: 28px;
                padding-bottom: 10px;
              }

              .Ultra_Pro_big_title {
                font-size: 40px;
              }

              ul.Ultra_Pro_text li.Ultra_Pro_li {
                font-size: 20px;
                padding-bottom: 0;
              }
            }
          }

          .Ultra_Pro_col_02 {
            .ultra_mobile_cell {
              width: auto;
              height: 460px;
            }

            img.ab_ultra_onlinelaunching {
              right: -18%;
              width: auto;
              height: 57px;
            }

            img.ab_ultra_play {
              right: -5%;
              top: -12%;
              width: auto;
              height: 55px;
            }

            img.ab_ultra_bell {
              left: -20%;
              width: auto;
              height: 70px;
            }
          }
        }
      }

      .sign_up_section {
        .sign_up_grid_row {
          .sign_up_grid {
            display: block;

            .sign_up_grid_item {
              width: 385px;
              height: 323px;
              border-radius: 20px;
              margin: auto;
              margin-top: 116px;

              .title {
                width: 69%;
                font-size: 17px;
                margin-top: 0% !important;
                padding-top: 100px;
                text-align: center;
                margin: auto;
              }

              p {
                font-size: 15px;
                width: 90%;
                margin-top: 12px;
                line-height: 24px;
              }

              &:nth-child(3) img {
                width: 182px;
                top: -65px !important;
                left: 25%;
              }

              &:nth-child(2) img {
                width: 196px;
                top: -85px !important;
                left: 24%;
              }

              img {
                width: 225px;
                top: -98px;
                left: 22%;
              }

              .sign_up_btn {
                button {
                  width: 195px;
                  font-size: 16px;
                  height: 53px;
                  bottom: -23px;
                  letter-spacing: 0.3px;
                }
              }
            }
          }
        }
      }

      .find_out_body {
        display: block;
        padding: 33px 0px 38px 0px;

        .find_out_section {
          .find_out_row {
            .find_out_col {
              text-align: center;

              .find_out_left {
                gap: 0 12px;
                padding: 0px 12px;

                .active-tabs {
                  &::before {
                    width: 7px;
                    height: 43px;
                    top: 9px;
                    left: -15px;
                    display: none;
                  }
                }

                button {
                  margin: auto;
                  margin-bottom: 15px !important;
                  width: 418px;
                  height: 60px;
                  font-size: 14px;
                  padding: 0px 10px;
                }
              }
            }
          }
        }

        .find_out_more_section {
          .find_out_more_row {
            .find_out_more_col {
              .Card_Img {
                width: 443px;
                height: 265px;
                margin: auto;
                display: flex;
              }
            }
          }
        }
      }

      .teach_fifth_section_row {
        .main_fifth_section {
          .support_main_section_row {
            .support_section_div {
              padding: 20px 20px 20px 20px;
              display: block;
              margin-top: 12px;

              .left_section {
                h1 {
                  font-size: 20px;
                  line-height: 30px;
                  margin-right: 135px;
                  width: 100%;
                  text-align: center;
                }
              }

              .right_section {
                .first {
                  font-size: 17px;
                  width: 100%;
                  text-align: center;
                }

                .email_call_section {
                  // display: flex;
                }

                .email_section {
                  margin: 6px auto;

                  .mail_box {
                    width: 80px;
                    height: 55px;
                    border-radius: 12px;
                    justify-content: flex-start;

                    img {
                      width: 35px !important;
                    }
                  }
                }

                h1 {
                  font-size: 20px;
                  line-height: 30px;
                  text-align: center;
                }
              }
            }
          }

          .teaching_btn button {
            width: 185px;
            height: 43px;
            font-size: 18px;
          }

          .title_section {
            h1 {
              font-size: 24px;
              margin-bottom: 20px;
            }
          }

          .fifth_grid_section {
            padding: 15px 10px 56px 10px;
            gap: 20px 8px;

            .last_box_div {
              .last_box_content {
                &:nth-child(1) h1 {
                  font-size: 22px !important;
                }

                .second {
                  font-size: 32px !important;
                }
              }
            }

            .grid_items_box {
              width: 365px;
              height: auto;

              .category_content {
                padding-left: 17px;

                span {
                  font-size: 17px;
                }

                h2 {
                  margin-bottom: 6px;
                  font-size: 20px;
                }
              }

              .category_icon {
                width: 60px;
                height: 60px;
                border-radius: 16px;

                .category {
                  width: 44px !important;
                  height: 44px !important;
                }

                img {
                  width: 38px;
                  height: 38px;
                }
              }
            }
          }
        }
      }
    }

    .enquiry_section_row {
      .enquiry_section_col {
        h1 {
          font-size: 46px !important;
        }

        .main_form {
          .enquiry_filed {
            textarea {
              height: 164px;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 1025px) and (max-width: 1140px) {
  .frontpage_wrapper {
    .frontpage_main_section {
      .front-story-wrapper {
        width: 98%;
      }

      .frontpage_slider_section {
        .frontpage_slider {
          padding-top: 72px;

          .new_banner {
            .new_banner_img {
              top: 13%;
              width: 935px;
            }

            p {
              span {
                font-size: 38px;
              }
            }

            .meta-verse {
              font-size: 38px !important;
            }

            .try-beta-version {
              font-size: 30px;
              top: 55%;
              right: 10%;
            }
          }

          .festival_banner {

            .decoration_div,
            .decoration-div {
              width: 85px;
            }

            .main_img {
              right: 0;
            }

            p {
              line-height: 60px;
              font-size: 36px !important;
              width: 60% !important;
              padding-left: 123px;
              top: 22% !important;

              span {
                font-size: 55px;
              }

              .main_content {
                margin-top: 2px !important;
                margin-bottom: 17px !important;
              }
            }

            span {
              font-size: 80px;
            }

            .main_img {
              width: 500px;
              top: 100px;
              right: -36px;
            }
          }

          .festival_second_banner {
            p {
              width: 56% !important;
              z-index: 1;
              line-height: 56px;
              top: 28% !important;
              padding-left: 67px !important;

              span {
                font-size: 50px;
              }

              .third_line {
                font-size: 40px;
              }

              button {
                width: 200px;
                height: 47px;
                font-size: 35px;
              }
            }

            .main_img {
              right: 0;
              top: 50px;
              width: 605px;
              height: 390px;
              object-fit: cover;
            }
          }

          .second_slider p {
            width: 38% !important;
          }

          .slick-arrow {
            display: block !important;
          }

          .slick-next {
            right: 32px;

            &:before {
              font-size: 47px !important;
            }
          }

          .slick-prev {
            left: 4px;

            &:before {
              font-size: 47px !important;
            }
          }

          .frontpage_banner {
            background-position: 47%;

            .first_banner {
              right: 2px;
              top: 47%;
              width: 620px;
            }

            p {
              font-size: 43px;
              padding-left: 50px;
              width: 49%;
              top: 33%;
            }

            .second_banner_img {
              display: block;
              width: 614px;
              top: 113px;
            }
          }

          .about_content {
            p {
              font-size: 22px;
              width: 86%;
            }
          }

          .second_slider p {
            top: 35% !important;
          }

          .third_slider {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 100%;

            p {
              width: 39% !important;
              font-size: 37px !important;
              line-height: 46px;

              span {
                font-size: 38px;
              }
            }
          }
        }
      }

      .free_demo_class {
        .Card_Container {
          padding: 15px 5px;
          width: 97%;
          margin: 0px auto 0px auto;

          .cards_row {
            grid-gap: 10px 0px !important;
            gap: 10px 0px !important;

            .Common_Card_Main_Container {
              width: 24%;
              margin: 0 0.5%;

              .Common_Card_Container {
                width: 100%;
                border-radius: 30px;

                .Card_Image_Container {
                  padding-top: 0px;
                  height: 105px;

                  .Card_Img {
                    width: 100%;
                    height: 100px;
                    border-radius: 25px 25px 0 0;
                  }

                  .mostReviewedWrapper {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .slick-arrow {
          display: block !important;
        }

        .slick-prev {
          left: -52px;
        }

        .slick-next {
          right: -30px;
        }

        .popular_class_box_section {
          .class_title {
            margin: 25px 0px 20px 0px;

            .Blue_Wawe {
              width: 200px;
              height: 14px;
            }

            h1 {
              font-size: 44px;
            }
          }
        }
      }

      .category_main_section {
        .category_row {
          .category_container_section {
            width: 95%;
            padding: 15px 10px;
            margin: 25px auto 25px auto;

            .category_container {
              display: flex;
              grid-gap: 5px;
              gap: 5px;

              .category_box {
                width: 19%;
                height: auto;
                border-radius: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .category_name {
                  p {
                    font-size: 15px;
                    width: 100%;
                    padding-bottom: 5px;
                  }
                }

                .category_image {
                  width: 55px;
                  height: 55px;
                  border-radius: 12px;
                  margin-top: 10px;

                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
              }
            }
          }

          .category_title {
            h2 {
              font-size: 44px;
              margin-top: 15px;
            }

            .Blue_Wawe {
              width: 180px;
              height: 15px;
            }
          }
        }
      }

      .site_banner_section {
        .slick-arrow {
          display: block !important;
        }

        .slick-next {
          right: 6px !important;
          top: 47% !important;

          &:before {
            font-size: 43px !important;
          }
        }

        .slick-prev {
          left: -21px;
          top: 47% !important;

          &:before {
            font-size: 43px !important;
          }
        }

        .site_banner_row {
          padding: 0px 20px;

          .site_banner {
            height: 196px;

            h1 {
              font-size: 34px;
              width: 47%;
            }
          }
        }
      }

      .Ultra_Pro_section {
        padding: 0px 60px 80px;
        background-position: 126% -93%;
        background-size: auto 822px;

        .Ultra_Pro_title_row {
          .Top_Cont {
            width: 678px;

            p {
              font-size: 50px;
            }
          }
        }

        .Ultra_Pro_content_row {
          grid-template-columns: 66% 34%;

          .Ultra_Pro_col_01 {
            grid-template-columns: 70% 30%;

            .Ultra_Pro_btn_block .btn_outter {
              a.btn_inner {
                padding: 3px;
                width: 190px;
              }

              a.btn_inner img {
                width: auto;
                height: 46px;
              }
            }

            .Ultra_Pro_content_block {
              .Ultra_Pro_min_font {
                font-size: 28px;
                padding-bottom: 10px;
              }

              .Ultra_Pro_big_title {
                font-size: 40px;
              }

              ul.Ultra_Pro_text li.Ultra_Pro_li {
                font-size: 20px;
                padding-bottom: 0;
              }
            }
          }

          .Ultra_Pro_col_02 {
            .ultra_mobile_cell {
              width: auto;
              height: 570px;
            }

            img.ab_ultra_onlinelaunching {
              right: -12%;
              width: auto;
              height: 66px;
            }

            img.ab_ultra_play {
              right: -5%;
              top: -12%;
              width: auto;
              height: 55px;
            }

            img.ab_ultra_bell {
              left: -20%;
              width: auto;
              height: 70px;
            }
          }
        }
      }

      .sign_up_section {
        .sign_up_grid_row {
          .sign_up_grid {
            gap: 0 12px;

            .sign_up_grid_item {
              width: 327px;
              height: 408px;

              .title {
                width: 63%;
                font-size: 22px;
              }

              p {
                font-size: 16px;
                width: 86%;
              }

              &:nth-child(3) img {
                width: 179px;
                top: -68px !important;
              }

              &:nth-child(2) img {
                width: 215px;
                top: -91px !important;
              }

              img {
                width: 246px;
                top: -114px;
              }

              .sign_up_btn {
                button {
                  width: 210px;
                  font-size: 17px;
                  height: 55px;
                  bottom: -24px;
                  letter-spacing: 0.3px;
                }
              }
            }
          }
        }
      }

      .find_out_body {
        gap: 0 50px;

        .find_out_more_section {
          .find_out_more_row {
            .find_out_more_col {
              .Card_Img {
                width: 470px;
                height: 363px;
              }
            }
          }
        }

        .find_out_section {
          .find_out_row {
            .find_out_col {
              h2 {
                font-size: 38px;
              }

              .find_out_left {
                .active-tabs {
                  &::before {
                    top: 17px;
                  }
                }

                button {
                  height: 85px;
                  font-size: 19px;
                  width: 433px;
                }
              }
            }
          }
        }
      }

      .teach_fifth_section_row {
        .main_fifth_section {
          .support_main_section_row {
            .support_section_div {
              padding: 20px 20px 20px 46px;

              .left_section {
                h1 {
                  font-size: 22px;
                }
              }

              .right_section {
                .first {
                  font-size: 19 + px;
                  width: 79%;
                }

                .email_section {
                  .mail_box {
                    width: 88px;
                    height: 63px;
                    justify-content: flex-start;
                  }
                }

                h1 {
                  font-size: 22px !important;
                }
              }
            }
          }

          .fifth_grid_section {
            padding: 60px 10px;
            gap: 50px 14px;

            .last_box_div {
              .last_box_content {
                &:nth-child(1) h1 {
                  font-size: 27px !important;
                }

                .second {
                  font-size: 32px !important;
                }
              }
            }

            .grid_items_box {
              width: 446px;
              height: 130px;

              .category_content {
                span {
                  font-size: 18px;
                }
              }

              .category_icon {
                width: 128px;

                img {
                  width: 65px;
                  height: 64px;
                }
              }
            }
          }
        }
      }
    }

    .enquiry_section_row {
      .enquiry_section_col {
        h1 {
          font-size: 46px !important;
        }

        .main_form {
          .enquiry_filed {
            textarea {
              height: 178px;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 1141px) and (max-width: 1280px) {
  .frontpage_wrapper {
    .frontpage_main_section {
      .front-story-wrapper {
        width: 98%;
      }

      .frontpage_slider_section {
        .frontpage_slider {
          padding-top: 72px;

          .new_banner {
            .new_banner_img {
              top: 13%;
              width: 955px;
            }

            p {
              span {
                font-size: 38px;
              }
            }

            .meta-verse {
              font-size: 38px !important;
            }

            .try-beta-version {
              font-size: 30px;
              top: 55%;
              right: 10%;
            }
          }

          .festival_banner {

            .decoration_div,
            .decoration-div {
              width: 109px;
            }

            p {
              line-height: 60px;
              font-size: 40px !important;
              width: 60% !important;
              padding-left: 123px;
              top: 22% !important;

              span {
                font-size: 56px;
              }

              .main_content {
                margin-top: 8px !important;
                margin-bottom: 27px !important;
              }
            }

            span {
              font-size: 80px;
            }

            .main_img {
              width: 520px;
              top: 100px;
              right: -36px;
            }
          }

          .festival_second_banner {
            p {
              width: 56% !important;
              z-index: 1;
              line-height: 56px;
              top: 28% !important;
              padding-left: 67px !important;

              span {
                font-size: 60px;
              }

              .third_line {
                font-size: 42px;
              }

              button {
                width: 208px;
                height: 50px;
                font-size: 37px;
              }
            }

            .main_img {
              right: 0;
              top: 30px;
              width: 715px;
              height: 474px;
              object-fit: cover;
            }
          }

          .second_slider p {
            width: 38% !important;
          }

          .slick-arrow {
            display: block !important;
          }

          .slick-next {
            right: 32px;

            &:before {
              font-size: 47px !important;
            }
          }

          .slick-prev {
            left: 4px;

            &:before {
              font-size: 47px !important;
            }
          }

          .frontpage_banner {
            background-position: 47%;

            .first_banner {
              right: 2px;
              top: 47%;
              width: 620px;
            }

            p {
              font-size: 43px;
              padding-left: 72px;
              width: 49%;
              top: 33%;
            }

            .second_banner_img {
              display: block;
              width: 614px;
              top: 113px;
            }
          }

          .about_content {
            p {
              font-size: 22px;
              width: 86%;
            }
          }

          .second_slider p {
            top: 35% !important;
          }

          .third_slider {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 100%;

            p {
              width: 39% !important;
              font-size: 37px !important;
              line-height: 46px;

              span {
                font-size: 38px;
              }
            }
          }
        }
      }

      .free_demo_class {
        .Card_Container {
          padding: 15px 5px;
          width: 97%;
          margin: 0px auto 0px auto;

          .cards_row {
            grid-gap: 10px 0px !important;
            gap: 10px 0px !important;

            .Common_Card_Main_Container {
              width: 24%;
              margin: 0 0.5%;

              .Common_Card_Container {
                width: 100%;
                border-radius: 30px;

                .Card_Image_Container {
                  padding-top: 0px;
                  height: 140px;

                  .Card_Img {
                    width: 100%;
                    height: 135px;
                    border-radius: 25px 25px 0 0;
                  }

                  .mostReviewedWrapper {
                    display: none;
                  }
                }
              }
            }
          }
        }

        .slick-arrow {
          display: block !important;
        }

        .slick-prev {
          left: -52px;
        }

        .slick-next {
          right: -30px;
        }

        .popular_class_box_section {
          .class_title {
            margin: 25px 0px 20px 0px;

            .Blue_Wawe {
              width: 200px;
              height: 14px;
            }

            h1 {
              font-size: 44px;
            }
          }
        }
      }

      .category_main_section {
        .category_row {
          .category_container_section {
            width: 95%;
            padding: 15px 10px;
            margin: 25px auto 25px auto;

            .category_container {
              display: flex;
              grid-gap: 5px;
              gap: 5px;

              .category_box {
                width: 19%;
                height: auto;
                border-radius: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .category_name {
                  p {
                    font-size: 15px;
                    width: 100%;
                    padding-bottom: 5px;
                  }
                }

                .category_image {
                  width: 55px;
                  height: 55px;
                  border-radius: 12px;
                  margin-top: 10px;

                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
              }
            }
          }

          .category_title {
            h2 {
              font-size: 44px;
              margin-top: 15px;
            }

            .Blue_Wawe {
              width: 180px;
              height: 15px;
            }
          }
        }
      }

      .site_banner_section {
        .slick-arrow {
          display: block !important;
        }

        .slick-next {
          right: 6px !important;
          top: 47% !important;

          &:before {
            font-size: 43px !important;
          }
        }

        .slick-prev {
          left: -21px;
          top: 47% !important;

          &:before {
            font-size: 43px !important;
          }
        }

        .site_banner_row {
          padding: 0px 20px;

          .site_banner {
            height: 196px;

            h1 {
              font-size: 34px;
              width: 47%;
            }
          }
        }
      }

      .Ultra_Pro_section {
        padding: 0px 60px 80px;
        background-position: 118% 85px;
        background-size: auto 860px;

        .Ultra_Pro_title_row {
          .Top_Cont {
            width: 678px;

            p {
              font-size: 52px;
            }
          }
        }

        .Ultra_Pro_content_row {
          grid-template-columns: 66% 34%;

          .Ultra_Pro_col_01 {
            grid-template-columns: 70% 30%;

            .Ultra_Pro_btn_block .btn_outter {
              a.btn_inner {
                padding: 3px;
                width: 190px;
              }

              a.btn_inner img {
                width: auto;
                height: 46px;
              }
            }

            .Ultra_Pro_content_block {
              .Ultra_Pro_min_font {
                font-size: 28px;
                padding-bottom: 10px;
              }

              .Ultra_Pro_big_title {
                font-size: 40px;
              }

              ul.Ultra_Pro_text li.Ultra_Pro_li {
                font-size: 20px;
                padding-bottom: 0;
              }
            }
          }

          .Ultra_Pro_col_02 {
            .ultra_mobile_cell {
              width: auto;
              height: 570px;
            }

            img.ab_ultra_onlinelaunching {
              right: -12%;
              width: auto;
              height: 66px;
            }

            img.ab_ultra_play {
              right: -5%;
              top: -12%;
              width: auto;
              height: 55px;
            }

            img.ab_ultra_bell {
              left: -20%;
              width: auto;
              height: 70px;
            }
          }
        }
      }

      .sign_up_section {
        .sign_up_grid_row {
          .sign_up_grid {
            gap: 0 12px;

            .sign_up_grid_item {
              width: 327px;
              height: 408px;

              .title {
                width: 63%;
                font-size: 22px;
              }

              p {
                font-size: 16px;
                width: 86%;
              }

              &:nth-child(3) img {
                width: 179px;
                top: -68px !important;
              }

              &:nth-child(2) img {
                width: 215px;
                top: -91px !important;
              }

              img {
                width: 246px;
                top: -114px;
              }

              .sign_up_btn {
                button {
                  width: 210px;
                  font-size: 17px;
                  height: 55px;
                  bottom: -24px;
                  letter-spacing: 0.3px;
                }
              }
            }
          }
        }
      }

      .find_out_body {
        gap: 0 50px;

        .find_out_more_section {
          .find_out_more_row {
            .find_out_more_col {
              .Card_Img {
                width: 470px;
                height: 363px;
              }
            }
          }
        }

        .find_out_section {
          .find_out_row {
            .find_out_col {
              h2 {
                font-size: 38px;
              }

              .find_out_left {
                .active-tabs {
                  &::before {
                    top: 17px;
                  }
                }

                button {
                  height: 85px;
                  font-size: 19px;
                  width: 433px;
                }
              }
            }
          }
        }
      }

      .teach_fifth_section_row {
        .main_fifth_section {
          .support_main_section_row {
            .support_section_div {
              padding: 20px 20px 20px 46px;

              .left_section {
                h1 {
                  font-size: 22px;
                }
              }

              .right_section {
                .first {
                  font-size: 19 + px;
                  width: 79%;
                }

                .email_section {
                  .mail_box {
                    width: 88px;
                    height: 63px;
                    justify-content: flex-start;
                  }
                }

                h1 {
                  font-size: 22px !important;
                }
              }
            }
          }

          .fifth_grid_section {
            padding: 60px 10px;
            gap: 50px 14px;

            .last_box_div {
              .last_box_content {
                &:nth-child(1) h1 {
                  font-size: 27px !important;
                }

                .second {
                  font-size: 32px !important;
                }
              }
            }

            .grid_items_box {
              width: 446px;
              height: 130px;

              .category_content {
                span {
                  font-size: 18px;
                }
              }

              .category_icon {
                width: 128px;

                img {
                  width: 65px;
                  height: 64px;
                }
              }
            }
          }
        }
      }
    }

    .enquiry_section_row {
      .enquiry_section_col {
        h1 {
          font-size: 48px !important;
        }

        .main_form {
          .enquiry_filed {
            textarea {
              height: 178px;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 1281px) and (max-width: 1440px) {
  .frontpage_wrapper {
    .frontpage_main_section {
      .front-story-wrapper {
        width: 98%;
      }

      .frontpage_slider_section {
        .frontpage_slider {
          padding-top: 72px;

          .new_banner {
            .new_banner_img {
              top: 42px;
              width: 1068px;
            }

            p {
              span {
                font-size: 38px;
              }
            }

            .meta-verse {
              font-size: 38px !important;
            }

            .try-beta-version {
              font-size: 30px;
              top: 55%;
              right: 10%;
            }
          }

          .festival_banner {
            p {
              line-height: 60px;
              font-size: 42px !important;
              width: 60% !important;
              padding-left: 123px;
              top: 23% !important;

              span {
                font-size: 63px;
              }

              .main_content {
                margin-top: 8px !important;
                margin-bottom: 27px !important;
              }
            }

            span {
              font-size: 80px;
            }

            .main_img {
              width: 560px;
              top: 100px;
            }
          }

          .festival_second_banner {
            p {
              width: 56% !important;
              z-index: 1;
              line-height: 65px;
              top: 25% !important;
              padding-left: 67px !important;

              span {
                font-size: 67px;
              }

              .third_line {
                font-size: 45px;
              }

              button {
                width: 230px;
                height: 58px;
              }
            }

            .main_img {
              right: 0;
              top: 30px;
              width: 774px;
              height: 474px;
              object-fit: cover;
            }
          }

          .second_slider p {
            width: 38% !important;
          }

          .slick-arrow {
            display: block !important;
          }

          .slick-next {
            right: 32px;

            &:before {
              font-size: 47px !important;
            }
          }

          .slick-prev {
            left: 4px;

            &:before {
              font-size: 47px !important;
            }
          }

          .frontpage_banner {
            background-position: 47%;

            .first_banner {
              right: 2px;
              top: 47%;
              width: 620px;
            }

            p {
              font-size: 43px;
              padding-left: 112px;
              width: 49%;
              top: 33%;
            }

            .second_banner_img {
              display: block;
              width: 614px;
              top: 113px;
            }
          }

          .about_content {
            p {
              font-size: 22px;
              width: 86%;
            }
          }

          .second_slider p {
            top: 42% !important;
          }

          .third_slider {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 100%;

            p {
              width: 39% !important;
              font-size: 37px !important;
              line-height: 46px;

              span {
                font-size: 38px;
              }
            }
          }
        }
      }

      .free_demo_class {
        .Card_Container {
          padding: 15px 5px;
          width: 97%;
          margin: 0px auto 0px auto;

          .cards_row {
            grid-gap: 10px 0px !important;
            gap: 10px 0px !important;

            .Common_Card_Main_Container {
              width: 24%;
              margin: 0 0.5%;

              .Common_Card_Container {
                width: 100%;
                border-radius: 30px;

                .Card_Image_Container {
                  padding-top: 0px;
                  height: 155px;

                  .Card_Img {
                    width: 100%;
                    height: 150px;
                    border-radius: 25px 25px 0 0;
                  }

                  .mostReviewedWrapper {
                    // display: none;
                  }
                }
              }
            }
          }
        }

        .slick-arrow {
          display: block !important;
        }

        .slick-prev {
          left: -52px;
        }

        .slick-next {
          right: -30px;
        }

        .popular_class_box_section {
          .class_title {
            margin: 25px 0px 20px 0px;

            .Blue_Wawe {
              width: 200px;
              height: 14px;
            }

            h2 {
              font-size: 44px;
            }
          }
        }
      }

      .category_main_section {
        .category_row {
          .category_container_section {
            width: 95%;
            padding: 15px 10px;
            margin: 25px auto 25px auto;

            .category_container {
              display: flex;
              grid-gap: 5px;
              gap: 5px;

              .category_box {
                width: 19%;
                height: auto;
                border-radius: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .category_name {
                  p {
                    font-size: 15px;
                    width: 100%;
                    padding-bottom: 5px;
                  }
                }

                .category_image {
                  width: 55px;
                  height: 55px;
                  border-radius: 12px;
                  margin-top: 10px;

                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
              }
            }
          }

          .category_title {
            h2 {
              font-size: 44px;
              margin-top: 15px;
            }

            .Blue_Wawe {
              width: 180px;
              height: 15px;
            }
          }
        }
      }

      .site_banner_section {
        .slick-arrow {
          display: block !important;
        }

        .slick-next {
          right: 6px !important;
          top: 47% !important;

          &:before {
            font-size: 43px !important;
          }
        }

        .slick-prev {
          left: -21px;
          top: 47% !important;

          &:before {
            font-size: 43px !important;
          }
        }

        .site_banner_row {
          padding: 0px 20px;

          .site_banner {
            height: 196px;

            h1 {
              font-size: 34px;
              width: 47%;
            }
          }
        }
      }

      .Ultra_Pro_section {
        padding: 0px 80px 80px;
        background-position: 110% -15%;
        background-size: auto 920px;

        .Ultra_Pro_title_row {
          .Top_Cont {
            width: 558px;

            p {
              font-size: 45px;
            }
          }
        }

        .Ultra_Pro_content_row {
          grid-template-columns: 66% 34%;

          .Ultra_Pro_col_01 {
            grid-template-columns: 70% 30%;

            .Ultra_Pro_content_block {
              .Ultra_Pro_min_font {
                font-size: 30px;
              }

              .Ultra_Pro_big_title {
                font-size: 40px;
              }

              ul.Ultra_Pro_text li.Ultra_Pro_li {
                font-size: 20px;
                padding-bottom: 8px;
              }
            }
          }

          .Ultra_Pro_col_02 {
            .ultra_mobile_cell {
              width: auto;
              height: 570px;
            }

            img.ab_ultra_onlinelaunching {
              right: -11%;
              width: auto;
              height: 80px;
            }

            img.ab_ultra_play {
              right: -5%;
              width: auto;
              height: 55px;
            }

            img.ab_ultra_bell {
              left: -20%;
              width: auto;
              height: 85px;
            }
          }
        }
      }

      .sign_up_section {
        .sign_up_grid_row {
          .sign_up_grid {
            gap: 0 12px;

            .sign_up_grid_item {
              width: 327px;
              height: 408px;

              .title {
                width: 63%;
                font-size: 22px;
              }

              p {
                font-size: 16px;
                width: 86%;
              }

              &:nth-child(3) img {
                width: 179px;
                top: -68px !important;
              }

              &:nth-child(2) img {
                width: 215px;
                top: -91px !important;
              }

              img {
                width: 246px;
                top: -114px;
              }

              .sign_up_btn {
                button {
                  width: 210px;
                  font-size: 17px;
                  height: 55px;
                  bottom: -24px;
                  letter-spacing: 0.3px;
                }
              }
            }
          }
        }
      }

      .find_out_body {
        gap: 0 50px;

        .find_out_more_section {
          .find_out_more_row {
            .find_out_more_col {
              .Card_Img {
                width: 470px;
                height: 363px;
              }
            }
          }
        }

        .find_out_section {
          .find_out_row {
            .find_out_col {
              h2 {
                font-size: 38px;
              }

              .find_out_left {
                .active-tabs {
                  &::before {
                    top: 17px;
                  }
                }

                button {
                  height: 85px;
                  font-size: 19px;
                  width: 433px;
                }
              }
            }
          }
        }
      }

      .teach_fifth_section_row {
        .main_fifth_section {
          .support_main_section_row {
            .support_section_div {
              padding: 20px 20px 20px 46px;

              .left_section {
                h1 {
                  font-size: 22px;
                }
              }

              .right_section {
                .first {
                  font-size: 19 + px;
                  width: 79%;
                }

                .email_section {
                  .mail_box {
                    width: 88px;
                    height: 63px;
                    justify-content: flex-start;
                  }
                }

                h1 {
                  font-size: 22px !important;
                }
              }
            }
          }

          .fifth_grid_section {
            padding: 60px 10px;
            gap: 50px 14px;

            .last_box_div {
              .last_box_content {
                &:nth-child(1) h1 {
                  font-size: 27px !important;
                }

                .second {
                  font-size: 32px !important;
                }
              }
            }

            .grid_items_box {
              width: 446px;
              height: 130px;

              .category_content {
                span {
                  font-size: 18px;
                }
              }

              .category_icon {
                width: 128px;

                img {
                  width: 65px;
                  height: 64px;
                }
              }
            }
          }
        }
      }
    }

    .enquiry_section_row {
      .enquiry_section_col {
        h1 {
          font-size: 48px !important;
        }

        .main_form {
          .enquiry_filed {
            textarea {
              height: 178px;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 1441px) and (max-width: 1680px) {
  .frontpage_wrapper {
    .frontpage_main_section {
      .front-story-wrapper {
        width: 98%;
      }

      .frontpage_slider_section {
        .frontpage_slider {
          padding-top: 72px;

          .new_banner {
            p {
              span {
                font-size: 45px;
              }
            }

            .meta-verse {
              font-size: 45px !important;
            }

            .try-beta-version {
              font-size: 30px;
              top: 60%;
              right: 12%;
            }
          }

          .festival_banner {
            p {
              font-size: 48px !important;
              width: 60% !important;
              padding-left: 123px;
              top: 18% !important;

              span {
                font-size: 70px;
              }

              .main_content {
                margin-top: 6px !important;
                margin-bottom: 20px !important;
              }
            }

            span {
              font-size: 80px;
            }

            .main_img {
              width: 645px;
              top: 88px;
            }
          }

          .festival_second_banner {
            p {
              width: 56% !important;
              z-index: 1;
              line-height: 65px;
              top: 25% !important;
              padding-left: 67px !important;

              span {
                font-size: 70px;
              }
            }

            .main_img {
              right: 0;
              top: 33px;
              width: 856px;
              height: 474px;
              object-fit: cover;
            }
          }

          .second_slider p {
            width: 38% !important;
          }

          .slick-arrow {
            display: block !important;
          }

          .slick-next {
            right: 32px;

            &:before {
              font-size: 47px !important;
            }
          }

          .slick-prev {
            left: 4px;

            &:before {
              font-size: 47px !important;
            }
          }

          .frontpage_banner {
            background-position: 47%;

            .first_banner {
              right: 2px;
              top: 47%;
              width: 620px;
            }

            p {
              font-size: 54px;
              padding-left: 112px;
              width: 49%;
              top: 33%;
            }

            .second_banner_img {
              display: block;
              width: 614px;
              top: 113px;
            }
          }

          .about_content {
            p {
              font-size: 22px;
              width: 86%;
            }
          }

          .second_slider p {
            top: 35% !important;
          }

          .third_slider {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 100%;

            p {
              width: 39% !important;
              font-size: 37px !important;
              line-height: 46px;

              span {
                font-size: 38px;
              }
            }
          }
        }
      }

      .free_demo_class {
        .Card_Container {
          padding: 24px 5px;
          width: 97%;
          margin: 0px auto 0px auto;

          .cards_row {
            grid-gap: 15px 0px !important;
            gap: 15px 0px !important;

            .Common_Card_Main_Container {
              width: 19%;
              margin: 0 0.5%;

              .Common_Card_Container {
                width: 100%;
                border-radius: 30px;

                .Card_Image_Container {
                  padding-top: 0px;
                  height: 155px;

                  .Card_Img {
                    width: 100%;
                    height: 150px;
                    border-radius: 25px 25px 0 0;
                  }

                  .mostReviewedWrapper {
                    // display: none;
                  }
                }
              }
            }
          }
        }

        .slick-arrow {
          display: block !important;
        }

        .slick-prev {
          left: -52px;
        }

        .slick-next {
          right: -30px;
        }

        .popular_class_box_section {
          .class_title {
            margin: 25px 0px 20px 0px;

            .Blue_Wawe {
              width: 200px;
              height: 14px;
            }

            h2 {
              font-size: 44px;
            }
          }
        }
      }

      .category_main_section {
        .category_row {
          .category_container_section {
            width: 95%;
            padding: 15px 10px;
            margin: 25px auto 25px auto;

            .category_container {
              display: flex;
              grid-gap: 15px 10px;
              gap: 15px 10px;

              .category_box {
                width: 19%;
                height: auto;
                border-radius: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .category_name {
                  padding: 5px 0;

                  p {
                    font-size: 18px;
                    width: 100%;
                    padding-bottom: 5px;
                  }
                }

                .category_image {
                  width: 95px;
                  height: 70px;
                  border-radius: 12px;
                  margin-top: 10px;

                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
              }
            }
          }

          .category_title {
            h2 {
              font-size: 44px;
              margin-top: 15px;
            }

            .Blue_Wawe {
              width: 180px;
              height: 15px;
            }
          }
        }
      }

      .site_banner_section {
        .slick-arrow {
          display: block !important;
        }

        .slick-next {
          right: 6px !important;
          top: 47% !important;

          &:before {
            font-size: 43px !important;
          }
        }

        .slick-prev {
          left: -21px;
          top: 47% !important;

          &:before {
            font-size: 43px !important;
          }
        }

        .site_banner_row {
          padding: 0px 20px;

          .site_banner {
            height: 196px;

            h1 {
              font-size: 34px;
              width: 47%;
            }
          }
        }
      }

      .Ultra_Pro_section {
        padding: 0px 100px 130px;
        background-position: 110% 130%;
        background-size: auto 965px;

        .Ultra_Pro_title_row {
          .Top_Cont {
            width: 640px;

            p {
              font-size: 55px;
            }
          }
        }

        .Ultra_Pro_content_row {
          grid-template-columns: 66% 34%;

          .Ultra_Pro_col_01 {
            .Ultra_Pro_content_block {
              .Ultra_Pro_min_font {
                font-size: 35px;
              }

              .Ultra_Pro_big_title {
                font-size: 60px;
              }

              ul.Ultra_Pro_text li.Ultra_Pro_li {
                font-size: 25px;
                padding-bottom: 8px;
              }
            }
          }

          .Ultra_Pro_col_02 {
            .ultra_mobile_cell {
              width: auto;
              height: 670px;
            }

            img.ab_ultra_onlinelaunching {
              right: -11%;
              width: auto;
              height: 80px;
            }

            img.ab_ultra_play {
              right: -5%;
              width: auto;
              height: 55px;
            }

            img.ab_ultra_bell {
              left: -20%;
              width: auto;
              height: 85px;
            }
          }
        }
      }

      .sign_up_section {
        .sign_up_grid_row {
          .sign_up_grid {
            gap: 0 12px;

            .sign_up_grid_item {
              width: 327px;
              height: 408px;

              .title {
                width: 63%;
                font-size: 22px;
              }

              p {
                font-size: 16px;
                width: 86%;
              }

              &:nth-child(3) img {
                width: 179px;
                top: -68px !important;
              }

              &:nth-child(2) img {
                width: 215px;
                top: -91px !important;
              }

              img {
                width: 246px;
                top: -114px;
              }

              .sign_up_btn {
                button {
                  width: 210px;
                  font-size: 17px;
                  height: 55px;
                  bottom: -24px;
                  letter-spacing: 0.3px;
                }
              }
            }
          }
        }
      }

      .find_out_body {
        gap: 0 50px;

        .find_out_more_section {
          .find_out_more_row {
            .find_out_more_col {
              .Card_Img {
                width: 470px;
                height: 363px;
              }
            }
          }
        }

        .find_out_section {
          .find_out_row {
            .find_out_col {
              h2 {
                font-size: 38px;
              }

              .find_out_left {
                .active-tabs {
                  &::before {
                    top: 17px;
                  }
                }

                button {
                  height: 85px;
                  font-size: 19px;
                  width: 433px;
                }
              }
            }
          }
        }
      }

      .teach_fifth_section_row {
        .main_fifth_section {
          .support_main_section_row {
            .support_section_div {
              padding: 20px 20px 20px 46px;

              .left_section {
                h1 {
                  font-size: 22px;
                }
              }

              .right_section {
                .first {
                  font-size: 19 + px;
                  width: 79%;
                }

                .email_section {
                  .mail_box {
                    width: 88px;
                    height: 63px;
                    justify-content: flex-start;
                  }
                }

                h1 {
                  font-size: 22px !important;
                }
              }
            }
          }

          .fifth_grid_section {
            padding: 60px 10px;
            gap: 50px 14px;

            .last_box_div {
              .last_box_content {
                &:nth-child(1) h1 {
                  font-size: 27px !important;
                }

                .second {
                  font-size: 32px !important;
                }
              }
            }

            .grid_items_box {
              width: 446px;
              height: 130px;

              .category_content {
                span {
                  font-size: 18px;
                }
              }

              .category_icon {
                width: 128px;

                img {
                  width: 65px;
                  height: 64px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 1681px) and (max-width: 1880px) {
  .frontpage_wrapper {
    .frontpage_main_section {
      .front-story-wrapper {
        width: 98%;
      }

      .frontpage_slider_section {
        .frontpage_slider {
          padding-top: 72px;

          .new_banner {
            .new_banner_img {
              top: 20px;
            }

            .meta-verse {
              font-size: 40px !important;
            }

            .try-beta-version {
              font-size: 30px;
              top: 45%;
              right: 15%;
            }
          }

          .festival_banner {
            p {
              font-size: 48px !important;
              padding-left: 123px;

              .main_content {
                margin-top: 6px !important;
                margin-bottom: 20px !important;
              }
            }

            span {
              font-size: 80px !important;
            }

            .main_img {
              width: 780px;
            }
          }

          .festival_second_banner {
            p {
              width: 56% !important;
              z-index: 1;
              line-height: 65px;
              top: 25% !important;
            }

            .main_img {
              right: 0;
              top: 33px;
              width: 1071px;
            }
          }

          .slick-slide {
            img {
              top: 48px;
            }
          }

          .second_slider p {
            width: 38% !important;
          }

          .slick-arrow {
            display: block !important;
          }

          .slick-next {
            right: 32px;

            &:before {
              font-size: 47px !important;
            }
          }

          .slick-prev {
            left: 4px;

            &:before {
              font-size: 47px !important;
            }
          }

          .frontpage_banner {
            background-position: 47%;

            .first_banner {
              right: 2px;
              top: 47%;
              width: 620px;
            }

            p {
              // font-size: 43px;
              padding-left: 112px;
              width: 49%;
              top: 33%;
            }

            .second_banner_img {
              display: block;
              width: 614px;
              top: 113px;
            }
          }

          .about_content {
            p {
              font-size: 22px;
              width: 86%;
            }
          }

          .second_slider p {
            top: 35% !important;
          }

          .third_slider {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 100%;

            p {
              width: 39% !important;
              font-size: 37px !important;
              line-height: 46px;

              span {
                font-size: 38px;
              }
            }
          }
        }
      }

      .free_demo_class {
        .Card_Container {
          padding: 24px 5px;
          width: 97%;
          margin: 0px auto 0px auto;

          .cards_row {
            grid-gap: 15px 0px !important;
            gap: 15px 0px !important;

            .Common_Card_Main_Container {
              width: 19%;
              margin: 0 0.5%;

              .Common_Card_Container {
                width: 100%;
                border-radius: 30px;

                .Card_Image_Container {
                  padding-top: 0px;
                  height: 155px;

                  .Card_Img {
                    width: 100%;
                    height: 150px;
                    border-radius: 25px 25px 0 0;
                  }

                  .mostReviewedWrapper {
                    // display: none;
                  }
                }
              }
            }
          }
        }

        .slick-arrow {
          display: block !important;
        }

        .slick-prev {
          left: -52px;
        }

        .slick-next {
          right: -30px;
        }

        .popular_class_box_section {
          .class_title {
            margin: 25px 0px 20px 0px;

            .Blue_Wawe {
              width: 200px;
              height: 14px;
            }

            h2 {
              font-size: 44px;
            }
          }
        }
      }

      .category_main_section {
        .category_row {
          .category_container_section {
            width: 95%;
            padding: 15px 10px;
            margin: 25px auto 25px auto;

            .category_container {
              display: flex;
              grid-gap: 15px 10px;
              gap: 15px 10px;

              .category_box {
                width: 19%;
                height: auto;
                border-radius: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .category_name {
                  padding: 5px 0;

                  p {
                    font-size: 18px;
                    width: 100%;
                    padding-bottom: 5px;
                  }
                }

                .category_image {
                  width: 95px;
                  height: 70px;
                  border-radius: 12px;
                  margin-top: 10px;

                  img {
                    width: 50px;
                    height: 50px;
                  }
                }
              }
            }
          }

          .category_title {
            h2 {
              font-size: 44px;
              margin-top: 15px;
            }

            .Blue_Wawe {
              width: 180px;
              height: 15px;
            }
          }
        }
      }

      .site_banner_section {
        .slick-arrow {
          display: block !important;
        }

        .slick-next {
          right: 6px !important;
          top: 47% !important;

          &:before {
            font-size: 43px !important;
          }
        }

        .slick-prev {
          left: -21px;
          top: 47% !important;

          &:before {
            font-size: 43px !important;
          }
        }

        .site_banner_row {
          padding: 0px 20px;

          .site_banner {
            height: 196px;

            h1 {
              font-size: 34px;
              width: 47%;
            }
          }
        }
      }

      .Ultra_Pro_section {
        padding: 0px 100px 155px;
      }

      .sign_up_section {
        .sign_up_grid_row {
          .sign_up_grid {
            gap: 0 12px;

            .sign_up_grid_item {
              width: 327px;
              height: 408px;

              .title {
                width: 63%;
                font-size: 22px;
              }

              p {
                font-size: 16px;
                width: 86%;
              }

              &:nth-child(3) img {
                width: 179px;
                top: -68px !important;
              }

              &:nth-child(2) img {
                width: 215px;
                top: -91px !important;
              }

              img {
                width: 246px;
                top: -114px;
              }

              .sign_up_btn {
                button {
                  width: 210px;
                  font-size: 17px;
                  height: 55px;
                  bottom: -24px;
                  letter-spacing: 0.3px;
                }
              }
            }
          }
        }
      }

      .find_out_body {
        gap: 0 50px;

        .find_out_more_section {
          .find_out_more_row {
            .find_out_more_col {
              .Card_Img {
                width: 470px;
                height: 363px;
              }
            }
          }
        }

        .find_out_section {
          .find_out_row {
            .find_out_col {
              h2 {
                font-size: 38px;
              }

              .find_out_left {
                .active-tabs {
                  &::before {
                    top: 17px;
                  }
                }

                button {
                  height: 85px;
                  font-size: 19px;
                  width: 433px;
                }
              }
            }
          }
        }
      }

      .teach_fifth_section_row {
        .main_fifth_section {
          .support_main_section_row {
            .support_section_div {
              padding: 20px 20px 20px 46px;

              .left_section {
                h1 {
                  font-size: 22px;
                }
              }

              .right_section {
                .first {
                  font-size: 19 + px;
                  width: 79%;
                }

                .email_section {
                  .mail_box {
                    width: 88px;
                    height: 63px;
                    justify-content: flex-start;
                  }
                }

                h1 {
                  font-size: 22px !important;
                }
              }
            }
          }

          .fifth_grid_section {
            padding: 60px 10px;
            gap: 50px 14px;

            .last_box_div {
              .last_box_content {
                &:nth-child(1) h1 {
                  font-size: 27px !important;
                }

                .second {
                  font-size: 32px !important;
                }
              }
            }

            .grid_items_box {
              width: 446px;
              height: 130px;

              .category_content {
                span {
                  font-size: 18px;
                }
              }

              .category_icon {
                width: 128px;

                img {
                  width: 65px;
                  height: 64px;
                }
              }
            }
          }
        }
      }
    }
  }
}