@import "../../../Styles/Color.scss";
@import "../../../Styles/Variable.scss";

.fa_Full {
  color: #ffb300 !important;
  font-size: 25px;
}

.fa-star {
  color: $primary_White;
  font-size: 25px;
}

.Star_Rating_Container {
  width: 25px;
  height: 25px;
}

.Common_Card_Container {
  //background-color: #dbd9fa;
  border-radius: 40px;
  // width: 250px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  height: fit-content;
  // height: 489px;
  // max-width: 350px;
  width: 305px;
  margin: auto auto 10px auto !important;
  border: 4px solid #ffffff;

  // box-shadow: 0px 0px 35px 0px rgb(0 0 0 / 18%);
  // margin: auto;
  &:hover {
    box-shadow: #bbe8f6 0px 3px 8px;
  }

  .Card_Image_Container {
    cursor: pointer;
    cursor: pointer;
    width: 100%;
    height: 165px;
    margin: 0%;
    position: relative;
    margin-top: -1px !important;
    margin: auto;

    .Card_Img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 37px 37px 0 0;
      // border: 3px solid rgba(255, 255, 255, 0.432);
      // box-shadow: $boxShadow;
    }

    .play_Btn {
      position: absolute;
      top: 35%;
      right: 45%;
      opacity: 0.5;
      cursor: pointer;
      background-color: #000;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      color: $primary_White;
      font-size: 12px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    }

    .mostReviewed {
      position: absolute;
      top: 70%;
      right: -1px;
      color: $primary_White;
      padding: 5px 10px 5px 30px;
      font-size: 13px;
      letter-spacing: 1px;
      border: 2px solid white;
      border-right: none;
      background: #aaa2f7;
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);

      &::after {
        content: "";
        position: absolute;
        width: 15px;
        height: 38px;
        background: $primary_White;
        top: -1px;
        left: 0;
        transform: rotate(-38deg);
      }
    }
  }

  .Card_Title_Container {
    cursor: pointer;
    display: flex;
    margin: 10px 20px;
    align-items: center;
    // justify-content: space-between;
    grid-template-columns: 260px minmax(0, 1fr);
    grid-gap: 15px;
    gap: 15px;

    .C_Title {
      font-size: 18px;
      word-break: break-word;
      text-transform: capitalize;
      height: 45px;
      width: 80% !important;
      margin-right: 10px;
      color: $primary_White;
      font-weight: bold;
      cursor: pointer;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .heart_Container {
      background-color: $primary_White;
      width: 35px !important;
      height: 35px !important;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid rgba(255, 255, 255, 0.432);
      cursor: pointer;

      img {
        width: 20px;
      }
    }
  }

  .Card_Title_Container_responsive_card {
    display: none;
  }

  .Card_Age_Container {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Age_Left_Side {
      //background-color: #c790ef;
      border-radius: 0px 30px 30px 0px;
      font-size: 18px;
      font-weight: 900;
      color: $primary_White;

      border-left: hidden;
      width: 130px;
      height: 39px;
      padding: 8px 15px;
      font-weight: 500;
      background-color: #576569;
    }

    .Eye_Right_Side {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 15px;

      p {
        margin-top: 5px;
        color: rgb(255, 255, 255);
      }
    }
  }

  .Price-Container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    color: #fff;

    .Ratting {
      border: 3px solid $primary_White;
      padding: 10px;
      border-right: none;
      border-radius: 20px 0 0 20px;
      display: flex;
      align-items: center;

      .Start_Img {
        width: 100%;
        height: 17px;
        object-fit: scale-down;
        padding-right: 7px;
      }
    }

    span.rating_class {
      display: none !important;
    }

    .Price {
      display: flex;
      align-items: center;
      margin-left: 15px;

      .Price-text {
        font-size: 32px;
        font-weight: bold;

        span {
          margin-right: 5px;
        }
      }

      .Sub-Price {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
      }
    }
  }

  .Card_Bottom_Container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .Card_Bottom {
      background-color: white;
      display: flex;
      align-items: center;
      width: 344px;
      width: 100%;
      margin-top: 20px;
      padding: 5px 20px;
      border-radius: 0px 0px 30px 30px;

      img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        object-fit: cover;
        margin-right: 10px;
      }

      .edu_badgeLevel {
        border-radius: 0;
        padding: 5px;
        height: auto;
      }

      p {
        text-transform: capitalize;
        font-size: 18px;
        margin-left: 0;
        color: $Primary_Font_Color;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.Card_Bottom_Container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .Card_Bottom {
    // background-color: white;
    // display: flex;
    // align-items: center;
    // width: 100%;
    // margin-top: 15px;
    // padding: 20px;
    // border-radius: 0px 0px 30px 30px;
    img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      object-fit: cover;
    }

    .edu_badgeLevel {
      border-radius: 0;
      padding: 12px;
      height: auto;
    }

    p {
      font-size: 18px;
      margin-left: 0;
      color: $Primary_Font_Color;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

@media (min-width: 1025px) and (max-width: 1140px) {
  .Common_Card_Container {
    max-width: auto;
    width: 304px;
  }
}

@media (min-width: 1141px) and (max-width: 1280px) {
  .Common_Card_Container {
    width: 304px;
  }
}

@media (min-width: 1020px) and (max-width: 1500px) {
  .Common_Card_Main_Container {
    // margin: auto !important;

    .Common_Card_Container {
      margin: auto !important;
    }
  }
}

@media (min-width: 1020px) and (max-width: 1300px) {
  .Common_Card_Container .Card_Title_Container .heart_Container {
    width: 25px !important;
    height: 25px !important;

    img {
      width: 12px !important;
    }
  }

  .Common_Card_Container .Card_Bottom_Container .Card_Bottom {
    margin-top: 10px !important;
  }

  .Common_Card_Container .Card_Bottom_Container {
    margin-top: 10px !important;
  }

  .Common_Card_Container .Card_Title_Container {
    margin: 10px 20px !important;
  }

  .Common_Card_Container .Card_Age_Container .Eye_Right_Side img {
    width: 25px !important;
  }

  .Common_Card_Container {
    .Card_Bottom_Container {
      .Price-Container {
        .Price {
          .Price-text {
            font-size: 30px;
          }

          .Sub-Price {
            font-size: 15px;
          }
        }
      }
    }
  }

  .fa_Full {
    font-size: 15px;
  }

  .fa-star {
    font-size: 15px;
  }

  .Star_Rating_Container {
    width: 25px;
    height: 25px;
  }

  .Responsive_card {
    width: 240px;
    margin: auto;

    .Card_Image_Container {
      .play_Btn {
        left: 40%;
      }
    }

    .Card_Title_Container {
      // margin: -15px 10px;
      .Title {
        font-size: 10px;
      }
    }

    .Card_Age_Container .Age_Left_Side {
      font-size: 12px;
      padding: 10px 25px;
    }

    .Card_Age_Container .Eye_Right_Side p {
      font-size: 15px;
    }

    .Card_Bottom_Container .Card_Bottom img {
      width: 50px;
      height: 50px;
    }

    .Card_Bottom_Container .Card_Bottom .edu_badgeLevel {
      border-radius: 0;
      padding: 12px;
      height: auto;
    }

    .Card_Bottom_Container .Card_Bottom p {
      font-size: 12px;
      margin-left: 10px;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .Common_Card_Container {
    max-width: 100%;
    width: 100%;
    border-radius: 30px;
    margin: auto auto 10px !important;

    .Card_Image_Container {
      padding-top: 0px;
      height: 100px;

      .Card_Img {
        border-radius: 25px 25px 0 0;
      }
    }

    .Price-Container {
      .Price {
        .Price-text {
          span {
            font-size: 23px;
          }
        }
      }
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        border-radius: 0px 0px 25px 25px;
        margin-top: 0px;

        p {
          font-size: 15px;
        }
      }
    }

    .Card_Age_Container {
      .Age_Left_Side {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 13px;
        height: 25px;
        border-radius: 0;
      }

      .Eye_Right_Side {
        display: none;
      }
    }

    .Card_Title_Container {
      position: relative;
      margin: 4px 1px;

      .C_Title {
        width: 100% !important;
        font-size: 13px;
        height: 31px;
      }

      .heart_Container {
        position: absolute;
        right: 0;
        display: none;
      }
    }

    .Card_Title_Container_responsive_card {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      .responsive_like_wrapper {
        width: 30%;
        background-color: $primary_White;
        width: 32px !important;
        height: 30px !important;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid rgba(255, 255, 255, 0.432);
        cursor: pointer;
        margin-left: 5px;

        img {
          width: 15px;
        }
      }

      .responsive_view_wrapper {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin-top: 2px;
          font-size: 14px;
          color: rgb(255, 255, 255);
        }
      }

      .responsive_review_wrapper {
        width: 30%;
        border-right: none;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Start_Img {
          width: 100%;
          height: 17px;
          object-fit: scale-down;
        }

        p {
          margin-top: 2px;
          font-size: 14px;
          color: white;
        }
      }
    }

    .Card_Image_Container {
      .mostReviewed {
        display: none;
      }
    }

    .Price-Container {
      display: none;
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        img {
          display: none;
        }
      }
    }
  }

  .Ex_Card_Container {
    .Description_Card_Outer_Container {
      .Description_Card_inner_Container {
        .Top_Container {
          .Content_Text {
            font-size: 14px;

            .help_desk_faq_section {
              .help_desk_faq_row {
                .help_desk_faq_col {
                  .help_desk_faq_box {
                    .help_desk_faq_content_box {
                      .help_desk_faq_inner_text {
                        padding: 17px 24px;
                      }

                      .help_desk_faq_inner_content {
                        padding: 0 20px 0 24px;
                        height: 62px;

                        .help_desk_faq_inner_title {
                          .help_desk_button_group {
                            height: 45px;
                            border-radius: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 580px) {
  .Common_Card_Container {
    max-width: 100%;
    width: 100%;
    border-radius: 30px;
    margin: auto auto 10px !important;

    .Price-Container {
      .Price {
        .Price-text {
          span {
            font-size: 23px;
          }
        }
      }
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        padding: 5px 10px;
        border-radius: 0px 0px 23px 23px;
        margin-top: 0;

        p {
          font-size: 15px;
        }
      }
    }

    .Card_Age_Container {
      .Age_Left_Side {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 13px !important;
        height: 25px;
        border-radius: 0;
      }

      .Eye_Right_Side {
        display: none;
      }
    }

    .Card_Title_Container {
      position: relative;
      margin: 4px 1px;

      .C_Title {
        width: 100% !important;
        font-size: 14px;
        height: 34px;
        margin-left: 5px;
      }

      .heart_Container {
        position: absolute;
        right: 0;
        display: none;
      }
    }

    .Card_Title_Container_responsive_card {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      .responsive_like_wrapper {
        width: 30%;
        background-color: $primary_White;
        width: 32px !important;
        height: 30px !important;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid rgba(255, 255, 255, 0.432);
        cursor: pointer;
        margin-left: 5px;

        img {
          width: 15px;
        }
      }

      .responsive_view_wrapper {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin-top: 2px;
          font-size: 14px;
          color: rgb(255, 255, 255);
        }
      }

      .responsive_review_wrapper {
        width: 30%;
        border-right: none;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Start_Img {
          width: 100%;
          height: 17px;
          object-fit: scale-down;
        }

        p {
          margin-top: 2px;
          font-size: 14px;
          color: white;
        }
      }
    }

    .Card_Image_Container {
      height: 105px;

      .Card_Img {
        border-radius: 25px 25px 0 0;
      }

      .mostReviewed {
        display: none;
      }
    }

    .Price-Container {
      display: none;
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        img {
          display: none;
        }
      }
    }
  }

  .Ex_Card_Container {
    .Description_Card_Outer_Container {
      .Description_Card_inner_Container {
        .Top_Container {
          .Content_Text {
            font-size: 14px;

            .help_desk_faq_section {
              .help_desk_faq_row {
                .help_desk_faq_col {
                  .help_desk_faq_box {
                    .help_desk_faq_content_box {
                      .help_desk_faq_inner_text {
                        padding: 17px 24px;
                      }

                      .help_desk_faq_inner_content {
                        padding: 0 20px 0 24px;
                        height: 62px;

                        .help_desk_faq_inner_title {
                          .help_desk_button_group {
                            height: 45px;
                            border-radius: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 550px) {
  .other_class_card_section {
    padding: 0 10px;

    .Common_Card_Container {
      .Price-Container {
        .Price {
          .Price-text {
            span {
              font-size: 23px;
            }
          }
        }
      }

      .Card_Bottom_Container {
        .Card_Bottom {
          p {
            font-size: 15px;
          }
        }
      }

      .Card_Age_Container {
        .Age_Left_Side {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          font-size: 13px;
          height: 25px;
          border-radius: 0;
        }

        .Eye_Right_Side {
          display: none;
        }
      }

      .Card_Title_Container {
        position: relative;
        margin: 4px 1px;

        .C_Title {
          width: 100% !important;
          font-size: 13px;
          height: 31px;
        }

        .heart_Container {
          position: absolute;
          right: 0;
          display: none;
        }
      }

      .Card_Title_Container_responsive_card {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        .responsive_like_wrapper {
          width: 30%;
          background-color: $primary_White;
          width: 32px !important;
          height: 30px !important;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 3px solid rgba(255, 255, 255, 0.432);
          cursor: pointer;
          margin-left: 5px;

          img {
            width: 15px;
          }
        }

        .responsive_view_wrapper {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          p {
            margin-top: 2px;
            font-size: 14px;
            color: rgb(255, 255, 255);
          }
        }

        .responsive_review_wrapper {
          width: 30%;
          border-right: none;
          border-radius: 20px 0 0 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .Start_Img {
            width: 100%;
            height: 17px;
            object-fit: scale-down;
          }

          p {
            margin-top: 2px;
            font-size: 14px;
            color: white;
          }
        }
      }

      .Card_Image_Container {
        .mostReviewed {
          display: none;
        }
      }

      .Price-Container {
        display: none;
      }

      .Card_Bottom_Container {
        .Card_Bottom {
          img {
            display: none;
          }
        }
      }
    }

    // .Common_Card_Container {
    //   max-width: 290px;

    //   .Card_Image_Container {
    //     height: auto;

    //     .Card_Img {
    //       height: 150px;
    //     }
    //   }
    // }
  }
}

@media (min-width: 581px) and (max-width: 767px) {
  .Common_Card_Container {
    max-width: 100%;
    width: 100%;
    border-radius: 30px;
    margin: auto auto 10px !important;

    .Price-Container {
      .Price {
        .Price-text {
          span {
            font-size: 23px;
          }
        }
      }
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        padding: 5px 10px;
        border-radius: 0px 0px 23px 23px;
        margin-top: 0;

        p {
          font-size: 15px;
        }
      }
    }

    .Card_Age_Container {
      .Age_Left_Side {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 13px !important;
        height: 25px;
        border-radius: 0;
      }

      .Eye_Right_Side {
        display: none;
      }
    }

    .Card_Title_Container {
      position: relative;
      margin: 4px 1px;

      .C_Title {
        width: 100% !important;
        font-size: 14px;
        height: 34px;
        margin-left: 5px;
      }

      .heart_Container {
        position: absolute;
        right: 0;
        display: none;
      }
    }

    .Card_Title_Container_responsive_card {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      .responsive_like_wrapper {
        width: 30%;
        background-color: $primary_White;
        width: 32px !important;
        height: 30px !important;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid rgba(255, 255, 255, 0.432);
        cursor: pointer;
        margin-left: 5px;

        img {
          width: 15px;
        }
      }

      .responsive_view_wrapper {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin-top: 2px;
          font-size: 14px;
          color: rgb(255, 255, 255);
        }
      }

      .responsive_review_wrapper {
        width: 30%;
        border-right: none;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Start_Img {
          width: 100%;
          height: 17px;
          object-fit: scale-down;
        }

        p {
          margin-top: 2px;
          font-size: 14px;
          color: white;
        }
      }
    }

    .Card_Image_Container {
      padding-top: 0px;
      height: 105px;

      .Card_Img {
        width: 100%;
        height: 100px;
        border-radius: 25px 25px 0 0;
      }

      .mostReviewed {
        display: none;
      }
    }

    .Price-Container {
      display: none;
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        img {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  .Common_Card_Container {
    max-width: 100%;
    width: 100%;
    border-radius: 30px;
    margin: auto auto 10px !important;

    .Price-Container {
      .Price {
        .Price-text {
          span {
            font-size: 23px;
          }
        }
      }
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        padding: 5px 10px;
        border-radius: 0px 0px 16px 16px;
        margin-top: 0;

        p {
          font-size: 15px;
        }
      }
    }

    .Card_Age_Container {
      .Age_Left_Side {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 13px;
        height: 25px;
        border-radius: 0;
      }

      .Eye_Right_Side {
        display: none;
      }
    }

    .Card_Title_Container {
      position: relative;
      margin: 4px 1px;

      .C_Title {
        width: 100% !important;
        font-size: 14px;
        height: 34px;
        margin-left: 5px;
      }

      .heart_Container {
        position: absolute;
        right: 0;
        display: none;
      }
    }

    .Card_Title_Container_responsive_card {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      .responsive_like_wrapper {
        width: 30%;
        background-color: $primary_White;
        width: 32px !important;
        height: 30px !important;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid rgba(255, 255, 255, 0.432);
        cursor: pointer;
        margin-left: 5px;

        img {
          width: 15px;
        }
      }

      .responsive_view_wrapper {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin-top: 2px;
          font-size: 14px;
          color: rgb(255, 255, 255);
        }
      }

      .responsive_review_wrapper {
        width: 30%;
        border-right: none;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Start_Img {
          width: 100%;
          height: 17px;
          object-fit: scale-down;
        }

        p {
          margin-top: 2px;
          font-size: 14px;
          color: white;
        }
      }
    }

    .Card_Image_Container {
      padding-top: 0px;
      height: 105px;

      .Card_Img {
        width: 100%;
        height: 100px;
        border-radius: 25px 25px 0 0;
      }

      .mostReviewed {
        display: none;
      }
    }

    .Price-Container {
      display: none;
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        img {
          display: none;
        }
      }
    }
  }

  .Ex_Card_Container {
    .Top_Container {
      .Content_Text {
        .help_desk_faq_section {
          .help_desk_faq_row {
            .help_desk_faq_col {
              .help_desk_faq_box {
                .help_desk_faq_content_box {
                  .help_desk_faq_inner_content {
                    height: 70px;

                    .help_desk_faq_inner_title {
                      .help_desk_button_group {
                        height: 50px;
                        width: 64px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .Description_Card_Outer_Container
      .Description_Card_inner_Container
      .Teacher_Profile_Wrapper
      .teacherReviewSection
      .reviewBox
      .reviewDetails
      p {
      padding: 0px 0;
    }

    .Description_Card_Outer_Container {
      .Description_Card_inner_Container {
        .Teacher_Profile_Wrapper {
          padding: 0px 10px;

          .totalReviews {
            div {
              padding: 10px 20px;
            }
          }

          .teacherReviewSection {
            padding: 0px 10px;
            .reviewBox {
              padding: 13px 0 15px 0;
              .reviewDetails {
                margin: 0px;
              }
            }
          }

          .Teacher_Profile_Container {
            .Content_Container {
              .Left_Side {
                .Card_SubTextSmall {
                  font-size: 14px;
                }

                .Card_Email_Btn {
                  margin: 18px 0px;
                  img {
                    width: 25px;
                    margin-right: 10px;
                  }
                }
              }

              .Right_Side {
                .Img_Tag {
                  div {
                    width: 45px;
                    height: 45px;
                    img {
                      width: 32px;
                    }
                  }
                }
                .Img_Container {
                  img {
                    width: 190px;
                    height: 190px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 551px) and (max-width: 767px) {
  .other_class_card_section {
    padding: 0 10px;

    .Common_Card_Container {
      max-width: 260px;

      .Card_Image_Container {
        height: auto;

        .Card_Img {
          height: 150px;
        }
      }
    }
  }
}

@media (min-width: 981px) and (max-width: 1024px) {
  .Common_Card_Container {
    max-width: 100%;
    width: 100%;
    border-radius: 30px;
    margin: auto auto 10px !important;

    .Price-Container {
      .Price {
        .Price-text {
          span {
            font-size: 23px;
          }
        }
      }
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        padding: 5px 10px;
        border-radius: 0px 0px 23px 23px;
        margin-top: 0;

        p {
          font-size: 15px;
        }
      }
    }

    .Card_Age_Container {
      .Age_Left_Side {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 13px;
        height: 25px;
        border-radius: 0;
      }

      .Eye_Right_Side {
        display: none;
      }
    }

    .Card_Title_Container {
      position: relative;
      margin: 4px 1px;

      .C_Title {
        width: 100% !important;
        font-size: 14px;
        height: 34px;
        margin-left: 5px;
      }

      .heart_Container {
        position: absolute;
        right: 0;
        display: none;
      }
    }

    .Card_Title_Container_responsive_card {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      .responsive_like_wrapper {
        width: 30%;
        background-color: $primary_White;
        width: 32px !important;
        height: 30px !important;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid rgba(255, 255, 255, 0.432);
        cursor: pointer;
        margin-left: 5px;

        img {
          width: 15px;
        }
      }

      .responsive_view_wrapper {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin-top: 2px;
          font-size: 14px;
          color: rgb(255, 255, 255);
        }
      }

      .responsive_review_wrapper {
        width: 30%;
        border-right: none;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Start_Img {
          width: 100%;
          height: 17px;
          object-fit: scale-down;
        }

        p {
          margin-top: 2px;
          font-size: 14px;
          color: white;
        }
      }
    }

    .Card_Image_Container {
      padding-top: 0px;
      height: 105px;

      .Card_Img {
        width: 100%;
        height: 100px;
        border-radius: 25px 25px 0 0;
      }

      .mostReviewed {
        display: none;
      }
    }

    .Price-Container {
      display: none;
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        img {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1140px) {
  .Common_Card_Container {
    max-width: 100%;
    width: 100%;
    border-radius: 30px;
    margin: auto auto 10px !important;

    .Price-Container {
      .Price {
        .Price-text {
          span {
            font-size: 23px;
          }
        }
      }
    }

    .Card_Bottom_Container {
      margin-top: 0px !important;

      .Card_Bottom {
        padding: 5px 10px;
        border-radius: 0px 0px 23px 23px;
        margin-top: 0;

        p {
          font-size: 15px;
        }
      }
    }

    .Card_Age_Container {
      margin-top: 15px;

      .Age_Left_Side {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 5px;
        font-size: 14px;
        height: 25px;
        border-radius: 0 15px 15px 0;
      }

      .Eye_Right_Side {
        // display: none;
      }
    }

    .Card_Title_Container {
      position: relative;
      margin: 4px 1px !important;

      .C_Title {
        width: 82% !important;
        font-size: 16px;
        height: 34px;
        margin-left: 5px;
      }

      .heart_Container {
        position: absolute;
        right: 5px;
        width: 30px !important;
        height: 30px !important;
        // display: none;
      }
    }

    .Card_Title_Container_responsive_card {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: none;

      .responsive_like_wrapper {
        width: 30%;
        background-color: $primary_White;
        width: 32px !important;
        height: 30px !important;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid rgba(255, 255, 255, 0.432);
        cursor: pointer;
        margin-left: 5px;

        img {
          width: 15px;
        }
      }

      .responsive_view_wrapper {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin-top: 2px;
          font-size: 14px;
          color: rgb(255, 255, 255);
        }
      }

      .responsive_review_wrapper {
        width: 30%;
        border-right: none;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Start_Img {
          width: 100%;
          height: 17px;
          object-fit: scale-down;
        }

        p {
          margin-top: 2px;
          font-size: 14px;
          color: white;
        }
      }
    }

    .Card_Image_Container {
      .mostReviewed {
        // display: none;
      }
    }

    .Price-Container {
      .Price {
        margin-left: 5px;
      }

      .Ratting {
        padding: 7px 10px;
      }
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        img {
          // display: none;
        }
      }
    }
  }
}

@media (min-width: 1141px) and (max-width: 1280px) {
  .Common_Card_Container {
    max-width: 100%;
    width: 100%;
    border-radius: 30px;
    margin: auto auto 10px !important;

    .Price-Container {
      .Price {
        .Price-text {
          span {
            font-size: 23px;
          }
        }
      }
    }

    .Card_Bottom_Container {
      margin-top: 0px !important;

      .Card_Bottom {
        padding: 5px 10px;
        border-radius: 0px 0px 23px 23px;
        margin-top: 0;

        p {
          font-size: 15px;
        }
      }
    }

    .Card_Age_Container {
      margin-top: 15px;

      .Age_Left_Side {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 5px;
        font-size: 14px;
        height: 25px;
        border-radius: 0 15px 15px 0;
      }

      .Eye_Right_Side {
        // display: none;
      }
    }

    .Card_Title_Container {
      position: relative;
      margin: 4px 1px !important;

      .C_Title {
        width: 82% !important;
        font-size: 16px;
        height: 34px;
        margin-left: 5px;
      }

      .heart_Container {
        position: absolute;
        right: 5px;
        width: 30px !important;
        height: 30px !important;
        // display: none;
      }
    }

    .Card_Title_Container_responsive_card {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: none;

      .responsive_like_wrapper {
        width: 30%;
        background-color: $primary_White;
        width: 32px !important;
        height: 30px !important;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid rgba(255, 255, 255, 0.432);
        cursor: pointer;
        margin-left: 5px;

        img {
          width: 15px;
        }
      }

      .responsive_view_wrapper {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin-top: 2px;
          font-size: 14px;
          color: rgb(255, 255, 255);
        }
      }

      .responsive_review_wrapper {
        width: 30%;
        border-right: none;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Start_Img {
          width: 100%;
          height: 17px;
          object-fit: scale-down;
        }

        p {
          margin-top: 2px;
          font-size: 14px;
          color: white;
        }
      }
    }

    .Card_Image_Container {
      .mostReviewed {
        // display: none;
      }
    }

    .Price-Container {
      .Price {
        margin-left: 5px;
      }

      .Ratting {
        padding: 7px 10px;
      }
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        img {
          // display: none;
        }
      }
    }
  }
}

@media (min-width: 1281px) and (max-width: 1440px) {
  .Common_Card_Container {
    max-width: 100%;
    width: 100%;
    border-radius: 30px;
    margin: auto auto 10px !important;

    .Price-Container {
      .Price {
        .Price-text {
          span {
            font-size: 25px;
          }
        }
      }
    }

    .Card_Bottom_Container {
      margin-top: 0px !important;

      .Card_Bottom {
        padding: 5px 10px;
        border-radius: 0px 0px 23px 23px;
        margin-top: 0;

        p {
          font-size: 15px;
        }
      }
    }

    .Card_Age_Container {
      margin-top: 15px;

      .Age_Left_Side {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 5px;
        font-size: 17px;
        height: 25px;
        border-radius: 0 20px 20px 0;
      }

      .Eye_Right_Side {
        // display: none;
      }
    }

    .Card_Title_Container {
      position: relative;
      margin: 4px 1px !important;

      .C_Title {
        width: 82% !important;
        font-size: 18px;
        height: 45px;
        margin-left: 7px;
      }

      .heart_Container {
        position: absolute;
        right: 5px;
        width: 35px !important;
        height: 35px !important;

        // display: none;
        img {
          width: 17px !important;
        }
      }
    }

    .Card_Title_Container_responsive_card {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: none;

      .responsive_like_wrapper {
        width: 30%;
        background-color: $primary_White;
        width: 32px !important;
        height: 30px !important;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid rgba(255, 255, 255, 0.432);
        cursor: pointer;
        margin-left: 5px;

        img {
          width: 15px;
        }
      }

      .responsive_view_wrapper {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin-top: 2px;
          font-size: 14px;
          color: rgb(255, 255, 255);
        }
      }

      .responsive_review_wrapper {
        width: 30%;
        border-right: none;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Start_Img {
          width: 100%;
          height: 17px;
          object-fit: scale-down;
        }

        p {
          margin-top: 2px;
          font-size: 14px;
          color: white;
        }
      }
    }

    .Card_Image_Container {
      .mostReviewed {
        // display: none;
      }
    }

    .Price-Container {
      margin-bottom: 15px;

      .Price {
        margin-left: 15px;
      }

      .Ratting {
        padding: 7px 10px;
      }
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        img {
          // display: none;
        }
      }
    }
  }
}

@media (min-width: 1441px) and (max-width: 1680px) {
  .Common_Card_Container {
    max-width: 100%;
    width: 100%;
    border-radius: 30px;
    margin: auto auto 10px !important;

    .Price-Container {
      .Price {
        .Price-text {
          span {
            font-size: 25px;
          }
        }
      }
    }

    .Card_Bottom_Container {
      margin-top: 0px !important;

      .Card_Bottom {
        padding: 5px 10px;
        border-radius: 0px 0px 23px 23px;
        margin-top: 0;

        p {
          font-size: 15px;
        }
      }
    }

    .Card_Age_Container {
      margin-top: 15px;

      .Age_Left_Side {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 5px;
        font-size: 17px;
        height: 25px;
        border-radius: 0 20px 20px 0;
      }

      .Eye_Right_Side {
        // display: none;
      }
    }

    .Card_Title_Container {
      position: relative;
      margin: 4px 1px !important;

      .C_Title {
        width: 82% !important;
        font-size: 18px;
        height: 45px;
        margin-left: 7px;
      }

      .heart_Container {
        position: absolute;
        right: 5px;
        width: 35px !important;
        height: 35px !important;

        // display: none;
        img {
          width: 17px !important;
        }
      }
    }

    .Card_Title_Container_responsive_card {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: none;

      .responsive_like_wrapper {
        width: 30%;
        background-color: $primary_White;
        width: 32px !important;
        height: 30px !important;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid rgba(255, 255, 255, 0.432);
        cursor: pointer;
        margin-left: 5px;

        img {
          width: 15px;
        }
      }

      .responsive_view_wrapper {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin-top: 2px;
          font-size: 14px;
          color: rgb(255, 255, 255);
        }
      }

      .responsive_review_wrapper {
        width: 30%;
        border-right: none;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Start_Img {
          width: 100%;
          height: 17px;
          object-fit: scale-down;
        }

        p {
          margin-top: 2px;
          font-size: 14px;
          color: white;
        }
      }
    }

    .Card_Image_Container {
      .mostReviewed {
        // display: none;
      }
    }

    .Price-Container {
      margin-bottom: 15px;

      .Price {
        margin-left: 15px;
      }

      .Ratting {
        padding: 7px 10px;
      }
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        img {
          // display: none;
        }
      }
    }
  }
}

@media (min-width: 1681px) and (max-width: 1880px) {
  .Common_Card_Container {
    max-width: 100%;
    width: 100%;
    border-radius: 30px;
    margin: auto auto 10px !important;

    .Price-Container {
      .Price {
        .Price-text {
          span {
            font-size: 25px;
          }
        }
      }
    }

    .Card_Bottom_Container {
      margin-top: 0px !important;

      .Card_Bottom {
        padding: 5px 10px;
        border-radius: 0px 0px 23px 23px;
        margin-top: 0;

        p {
          font-size: 15px;
        }
      }
    }

    .Card_Age_Container {
      margin-top: 15px;

      .Age_Left_Side {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 5px;
        font-size: 17px;
        height: 25px;
        border-radius: 0 20px 20px 0;
      }

      .Eye_Right_Side {
        // display: none;
      }
    }

    .Card_Title_Container {
      position: relative;
      margin: 4px 1px !important;

      .C_Title {
        width: 82% !important;
        font-size: 18px;
        height: 45px;
        margin-left: 7px;
      }

      .heart_Container {
        position: absolute;
        right: 5px;
        width: 35px !important;
        height: 35px !important;

        // display: none;
        img {
          width: 17px !important;
        }
      }
    }

    .Card_Title_Container_responsive_card {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: none;

      .responsive_like_wrapper {
        width: 30%;
        background-color: $primary_White;
        width: 32px !important;
        height: 30px !important;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid rgba(255, 255, 255, 0.432);
        cursor: pointer;
        margin-left: 5px;

        img {
          width: 15px;
        }
      }

      .responsive_view_wrapper {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          margin-top: 2px;
          font-size: 14px;
          color: rgb(255, 255, 255);
        }
      }

      .responsive_review_wrapper {
        width: 30%;
        border-right: none;
        border-radius: 20px 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Start_Img {
          width: 100%;
          height: 17px;
          object-fit: scale-down;
        }

        p {
          margin-top: 2px;
          font-size: 14px;
          color: white;
        }
      }
    }

    .Card_Image_Container {
      .mostReviewed {
        // display: none;
      }
    }

    .Price-Container {
      margin-bottom: 15px;

      .Price {
        margin-left: 15px;
      }

      .Ratting {
        padding: 7px 10px;
      }
    }

    .Card_Bottom_Container {
      .Card_Bottom {
        img {
          // display: none;
        }
      }
    }
  }
}
